import React from 'react';

export const FOOTER_ICON = ({ color = 'black', type, label }) => {
  const createSvgDataUrl = (color, type) => {
    // Define SVGs based on the type
    const svgs = {
      HomeIcon: `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.49592 1.44557C9.62973 1.32753 9.81112 1.26123 10.0002 1.26123C10.1893 1.26123 10.3707 1.32753 10.5045 1.44557L18.7733 8.74896C18.8391 8.80914 18.9179 8.85712 19.005 8.89012C19.0921 8.92312 19.1857 8.94047 19.2805 8.94116C19.3752 8.94184 19.4692 8.92586 19.5569 8.89413C19.6446 8.8624 19.7242 8.81556 19.7912 8.75635C19.8582 8.69714 19.9111 8.62674 19.947 8.54926C19.9828 8.47178 20.0008 8.38877 20 8.30508C19.9991 8.22138 19.9794 8.13868 19.9419 8.06179C19.9045 7.98491 19.8501 7.91538 19.7819 7.85726L11.5141 0.553864C11.3153 0.378268 11.0793 0.238977 10.8195 0.143945C10.5598 0.0489125 10.2814 0 10.0002 0C9.71908 0 9.44068 0.0489125 9.18093 0.143945C8.92118 0.238977 8.68516 0.378268 8.48636 0.553864L0.217621 7.85726C0.149493 7.91543 0.095164 7.98501 0.0578043 8.06193C0.0204446 8.13884 0.000802512 8.22156 2.40815e-05 8.30526C-0.000754349 8.38895 0.0173464 8.47195 0.0532704 8.5494C0.0891943 8.62685 0.142222 8.6972 0.209259 8.75636C0.276296 8.81551 0.356 8.86228 0.443721 8.89394C0.531441 8.9256 0.625422 8.9415 0.720178 8.94074C0.814934 8.93997 0.908568 8.92254 0.995618 8.88947C1.08267 8.8564 1.16139 8.80835 1.22719 8.74812L9.49592 1.44557Z"
                fill="${color}"
              />
              <path
                d="M10 3.35294L17.692 10.1765C17.7203 10.2052 17.7486 10.2321 17.7778 10.2589V16.2021C17.7778 17.1945 16.9859 18 16.0101 18H12.8283C12.6408 18 12.4609 17.9242 12.3283 17.7894C12.1957 17.6545 12.1212 17.4716 12.1212 17.2808V12.9659C12.1212 12.7751 12.0467 12.5922 11.9141 12.4573C11.7815 12.3225 11.6017 12.2467 11.4141 12.2467H8.58586C8.39833 12.2467 8.21849 12.3225 8.08588 12.4573C7.95328 12.5922 7.87879 12.7751 7.87879 12.9659V17.2808C7.87879 17.4716 7.80429 17.6545 7.67169 17.7894C7.53909 17.9242 7.35924 18 7.17172 18H3.9899C3.52108 18 3.07147 17.8106 2.73996 17.4734C2.40846 17.1362 2.22222 16.6789 2.22222 16.2021V10.2589C2.25155 10.2322 2.28016 10.2047 2.30801 10.1765L10 3.35294Z"
                fill="${color}"
              />
            </svg>`,
      OrdersIcon: `<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 6.38462L10 1L5.5 3.69231M19 6.38462V15.6154L10 21M19 6.38462L14.5 9.07692M1 6.38462L10 11.7692M1 6.38462V15.6154L10 21M1 6.38462L5.5 3.69231M10 11.7692V21M10 11.7692L14.5 9.07692M14.5 9.07692L5.5 3.69231"
                stroke="${color}"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>`,
      CashbackIcon: `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_10766_2548)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.89692 4.5458C9.97876 4.16796 10.3514 3.92801 10.7292 4.00986L13.5384 4.61833C15.0521 4.94621 16.0903 6.28288 16.0952 7.77383L16.6823 7.90102C17.0602 7.98286 17.3001 8.35551 17.2183 8.73334C17.1365 9.11118 16.7638 9.35113 16.386 9.26929L15.7989 9.14213C15.1789 10.4942 13.6825 11.2853 12.1654 10.9567L10.0414 10.4966L9.52289 12.8905C9.44105 13.2683 9.0684 13.5083 8.69057 13.4264C8.31273 13.3446 8.07278 12.972 8.15462 12.5941L8.81323 9.55355C8.81524 9.54101 8.81761 9.52846 8.82033 9.51589C8.82305 9.50333 8.82609 9.49092 8.82945 9.47867L9.21142 7.71524L8.58556 7.57967C8.20772 7.49783 7.96777 7.12519 8.04961 6.74735C8.13145 6.36951 8.5041 6.12956 8.88193 6.2114L9.50779 6.34697L9.88037 4.6269C9.8827 4.61614 9.88527 4.60548 9.88806 4.59495C9.89042 4.57859 9.89337 4.5622 9.89692 4.5458ZM10.5797 8.01161L10.3378 9.12835L12.4618 9.58843C13.215 9.75157 13.9592 9.42906 14.3701 8.83264L10.5797 8.01161ZM14.6665 7.46437L10.8761 6.64334L11.118 5.52652L13.242 5.9866C13.9937 6.14942 14.5391 6.75004 14.6665 7.46437Z"
                  fill="${color}"
                />
              </g>
              <path
                d="M6.43646 12.8003V18.141L8.71317 19.3085C10.2544 20.2081 11.7972 20.2401 13.3396 19.3085C15.4632 18.3364 17.5392 17.133 19.5642 15.6703C20.5954 14.6592 19.6004 13.2071 18.2021 13.6641L15.4066 15.0398L15.1255 15.6918C14.5647 16.5641 13.6483 16.7754 12.5366 16.777C12.3257 16.777 12.0842 16.8288 11.8612 16.8117C11.2204 16.809 11.2463 16.333 11.8612 16.3634C12.7526 16.443 13.4179 16.5801 14.0764 16.1001C14.9033 15.5374 14.6621 14.2264 13.8116 14.1761H11.9323C9.54053 12.6814 9.09013 12.8003 6.43646 12.8003Z"
                stroke="${color}"
                stroke-width="1.2"
                stroke-linecap="round"
              />
              <path
                d="M5.91415 12H3.49988C3.22374 12 2.99988 12.2239 2.99988 12.5V17.8908C2.99988 18.1669 3.22374 18.3908 3.49988 18.3908H6.41415V12.5C6.41415 12.2239 6.19029 12 5.91415 12Z"
                stroke="${color}"
                stroke-width="1.2"
                stroke-linecap="round"
              />
              <path
                d="M3.92908 6.66259C4.54757 4.52383 5.99036 2.71837 7.94003 1.64338C9.8897 0.568386 12.1866 0.311929 14.3253 0.930424C16.4641 1.54892 18.2695 2.9917 19.3445 4.94137C20.4195 6.89105 20.676 9.1879 20.0575 11.3267"
                stroke="${color}"
                stroke-width="1.2"
              />
              <path
                d="M5.32565 6.60505L3.42558 7.94176L2.84731 5.69172L5.32565 6.60505Z"
                fill="${color}"
                stroke="${color}"
              />
              <defs>
                <clipPath id="clip0_10766_2548">
                  <rect
                    width="11.9887"
                    height="11.9887"
                    fill="white"
                    transform="translate(7.53784 2) rotate(12.2218)"
                  />
                </clipPath>
              </defs>
            </svg>`,
      HelpIcon: `<svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_11322_10248)">
                <path d="M7.34812 13.1191C7.40942 13.1395 7.47268 13.1498 7.53615 13.1498H7.53622C7.67329 13.1498 7.80678 13.1006 7.91195 13.0116C8.14059 12.8181 8.24872 12.4904 8.08169 11.4555C7.93386 10.5378 7.70487 10.0572 7.31616 9.84877C7.23106 9.80307 7.14093 9.77257 7.04757 9.75788C7.05053 8.04748 7.63851 6.46687 8.66465 5.41559C9.54116 4.51723 10.6949 4.04263 12.0014 4.04263C13.3078 4.04263 14.4616 4.51723 15.3382 5.41559C16.3643 6.46687 16.9523 8.0472 16.9552 9.75788C16.8619 9.77257 16.7717 9.80307 16.6866 9.84877C16.2979 10.0572 16.069 10.5378 15.9211 11.4555C15.8579 11.8474 15.832 12.1505 15.842 12.3805C15.5396 12.5419 14.9985 12.7419 14.0989 12.8195C13.9572 12.5841 13.7044 12.4393 13.4309 12.4393H12.9119C12.4803 12.4393 12.1292 12.7893 12.1292 13.2195C12.1292 13.6498 12.4803 13.9998 12.9119 13.9998H13.4309C13.7362 13.9998 14.0106 13.8248 14.1392 13.551C14.9574 13.4832 15.6328 13.3156 16.1475 13.0519C16.2436 13.1161 16.3533 13.1498 16.4666 13.1498C16.5306 13.1498 16.5939 13.1395 16.6548 13.1191C17.0687 12.9817 17.9418 12.0411 17.9981 10.0104C18.0558 7.93096 17.3597 5.99139 16.0881 4.68839C15.0102 3.58376 13.597 2.99976 12.0014 2.99976C10.4058 2.99976 8.99263 3.58376 7.91463 4.68839C6.64314 5.99142 5.94699 7.93096 6.00473 10.0104C6.06106 12.0411 6.9341 12.9817 7.34812 13.1191Z" fill="${color}" />
                <mask id="mask0_11322_10248" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="5" width="16" height="16">
                  <path d="M18.5508 20.4H5.44909L5.54768 19.148C5.653 18.6264 6.0033 18.184 6.4952 17.9574L9.35436 16.807L9.36618 16.8023L9.37779 16.797C10.044 16.4955 10.4807 15.8305 10.4807 15.096V14.6804C10.4807 14.422 10.4371 14.1664 10.3516 13.9221L10.2986 13.7707L10.2266 13.7086C10.2173 13.6959 10.2062 13.6805 10.1934 13.6625C10.1475 13.598 10.0808 13.5019 10.0003 13.3792C9.83893 13.1333 9.62427 12.784 9.41031 12.3709C8.97387 11.5284 8.5772 10.4902 8.5772 9.54537C8.5772 7.17201 10.0671 5.6 11.9999 5.6C13.9327 5.6 15.4227 7.17199 15.4227 9.54537C15.4227 9.94668 15.3509 10.4043 15.1987 10.909L15.0998 11.0513C15.0787 11.0729 15.063 11.091 15.054 11.1013L15.0529 11.1026C15.0311 11.1277 15.0062 11.1581 14.9863 11.1824C14.9818 11.1879 14.9775 11.1931 14.9736 11.1978C14.9242 11.2579 14.8757 11.3148 14.819 11.3694C14.7498 11.436 14.676 11.4919 14.593 11.5308C14.2447 11.3436 13.8559 11.2458 13.4561 11.2458H12.9274C12.2915 11.2458 11.7629 11.4627 11.3975 11.8604C11.0407 12.2486 10.8929 12.7508 10.8929 13.2325C10.8929 13.698 11.0458 14.1901 11.3986 14.5719C11.7606 14.9635 12.2864 15.1883 12.9274 15.1883H13.4561C13.4954 15.1883 13.5346 15.1874 13.5738 15.1854C13.6177 15.3988 13.6863 15.6172 13.7734 15.8181C13.9199 16.1562 14.1851 16.5995 14.6245 16.7982L14.636 16.8034L14.6478 16.8081L17.5024 17.9563C17.9971 18.1843 18.3467 18.627 18.4515 19.1426L18.5508 20.4Z" stroke="black" stroke-width="1.2" />
                </mask>
                <g mask="url(#mask0_11322_10248)">
                  <rect x="4.79999" y="3" width="14.4" height="18" rx="1" fill="${color}" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_11322_10248">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>`,
      AccountIcon: `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.6472 18.4722C16.8708 17.4442 15.8664 16.6106 14.713 16.037C13.5596 15.4634 12.2887 15.1654 11.0006 15.1666C9.71239 15.1654 8.44154 15.4634 7.28813 16.037C6.13473 16.6106 5.13027 17.4442 4.35389 18.4722M17.6472 18.4722C19.1622 17.1246 20.2307 15.3483 20.713 13.3789C21.1953 11.4094 21.0675 9.3399 20.3466 7.44475C19.6257 5.5496 18.3458 3.91836 16.6766 2.76736C15.0073 1.61637 13.0276 1 11 1C8.9724 1 6.9927 1.61637 5.32345 2.76736C3.6542 3.91836 2.37426 5.5496 1.65337 7.44475C0.932491 9.3399 0.804726 11.4094 1.28702 13.3789C1.76932 15.3483 2.83889 17.1246 4.35389 18.4722M17.6472 18.4722C15.8184 20.1035 13.4513 21.0035 11.0006 21C8.54948 21.0038 6.18304 20.1038 4.35389 18.4722M14.3339 8.49979C14.3339 9.38386 13.9827 10.2317 13.3576 10.8569C12.7325 11.482 11.8846 11.8332 11.0006 11.8332C10.1165 11.8332 9.26865 11.482 8.64353 10.8569C8.01841 10.2317 7.66722 9.38386 7.66722 8.49979C7.66722 7.61572 8.01841 6.76786 8.64353 6.14273C9.26865 5.5176 10.1165 5.1664 11.0006 5.1664C11.8846 5.1664 12.7325 5.5176 13.3576 6.14273C13.9827 6.76786 14.3339 7.61572 14.3339 8.49979Z"
                stroke="${color}"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>`,
    };

    // Get the SVG content based on the provided type
    const svgContent = svgs[type] || svgs.circle; // Default to circle if type is invalid
    return `data:image/svg+xml;base64,${btoa(svgContent)}`;
  };

  const svgDataUrl = createSvgDataUrl(color, type);

  return (
    <>
      <img src={svgDataUrl} alt={`Dynamic ${type} SVG`} />
      <span className="mlLbl-fine-light" style={{ color: color }}>
        {label}
      </span>
    </>
  );
};


export const FOOTER_COLOR = {
  DEFAULT: '#0A2757',
  ACTIVE: '#E64E75'
}