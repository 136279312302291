import { useQuery } from '@tanstack/react-query';
import PaymentDAO from '../utils/dao/PaymentDAO';

export const useVoucherCenter = userType => {
  const paymentDao = new PaymentDAO();

  return useQuery({
    queryKey: ['getVoucherCenter', userType],
    queryFn: async () => {
      const response = await paymentDao.getVoucherCenter(userType);
      return response?.result;
    },
    onError: error => {
      console.error('Query Voucher Center error:', error);
    },
  });
};
