import React, { useEffect, useState } from 'react';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import Vouchers from './Vouchers';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import { useSelector } from 'react-redux';
import VoucherCardLoader from '../../../../../shared/components/VoucherCardLoader';
import { useVoucherCenter } from '../../../../../shared/hooks/useVoucherCenter';
import { Card } from 'antd';
import moment from 'moment-timezone';
import {
  getAmountOff,
  getCourierLogo,
  getTimeLeft,
} from '../../../../../shared/utils/helpers/purefunctions';
import { COURIER_LOGO } from '../../../../../shared/utils/enums/CourierImages';
import { MODULE_PATH } from '../../../../../shared/constants/Module';

const VoucherCenter = () => {
  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);

  const prevNavigate = {
    label: 'Voucher Center',
    imgPath: '',
    path: location?.state?.link || '/',
  };

  const prevPath = MODULE_PATH.EXTRAS.VOUCHER_CENTER;

  const { data, isLoading, error } = useVoucherCenter(userType);

  const latestThreeVoucher =
    data && data.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))).slice(0, 3);

  console.log('voucherList', latestThreeVoucher, isLoading, error);

  const DisplayVoucherList = () => {
    if (!data && data.length === 0) {
      return <div style={{ marginTop: '50%', textAlign: 'center' }}>No avaible vouchers.</div>;
    }

    if (isLoading) return <VoucherCardLoader count={3} />;

    return (
      data &&
      data.map((data, index) => {
        const expirationLeft = getTimeLeft(data.expiration);
        const amountOff = getAmountOff(data);
        const logo = getCourierLogo(data, ICON.PINK_PARCELS_LOGO, COURIER_LOGO);
        return (
          <Vouchers
            key={index}
            voucherData={data}
            expirationLeft={expirationLeft}
            description={data.description}
            amountOff={amountOff}
            from="voucher-center"
            logo={logo}
            voucherIcon={ICON.VOUCHER_CARD_ICON_GREEN}
            prevPath={prevPath}
          />
        );
      })
    );
  };

  return (
    <div className="voucher__center__container">
      <StickyHeader title="Voucher Center" onPrevNavigate={prevNavigate} />
      <DisplayVoucherList />
    </div>
  );
};

export default VoucherCenter;
