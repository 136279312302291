import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import PickupNow from '../../../../../shared/assets/svg/home-booking/pickup_now.svg';
import PickupLater from '../../../../../shared/assets/svg/home-booking/pickup_later.svg';
import { DAY_MMM_D_HH_MM } from '../../../../../shared/utils/enums/dateFormats';

import moment from 'moment-timezone';
import {
  COURIER_VEHICLE_TYPES,
  FOOD_PANDA,
  PANDAGO,
} from '../../../../../shared/utils/enums/Providers';
import { ON_DEMAND_ICON, STANDARD_ICON, ICON } from '../../../../../shared/utils/enums/AppIcons';
import {
  capitalizeName,
  formatBills,
  formatUnderscoredText,
  isEmpty,
  getConvenienceFee,
} from '../../../../../shared/utils/helpers/purefunctions';
import { BOOK_ANY } from '../../../../../shared/utils/enums/AppConstants';
import LoaderSpinner from '../../../../../shared/components/LoaderSpinner';
import { COURIER_PRODUCT_SIZES } from '../../../../../shared/utils/enums/StandardDelivery';
import IconItemDetails from '../../../../../shared/assets/svg/home-booking/icon_item-details.svg';
import IconFindCourier from '../../../../../shared/assets/svg/home-booking/icon_find-courier.svg';
import arrowRight from '../../../../../shared/assets/images/OndemandDelivery/arrow_right.svg';
import kgImage from '../../../../../shared/assets/images/OndemandDelivery/kg.svg';
import ItemDetailsList from '../../../../../shared/components/ItemDetailsList';
import approxWeight from '../../../../../shared/assets/images/OndemandDelivery/approxWeight.svg';
import Schedule from '../../../OndemandDelivery/components/Schedule';
// import FooterBtnHome from '../../../../../shared/components/FooterBtnHome';
import { useNavigate } from 'react-router';
import { COURIER_IMAGES } from '../../../../../shared/utils/enums/CourierImages';
import { setPrevRoute } from '../../../../../shared/redux/home/actions';
import {
  setVehicleType,
} from '../../../../../shared/redux/onDemand/actions';
import {
  setProduct,
} from '../../../../../shared/redux/standard/actions';
import { message } from 'antd';

function BookingFields({
  isOndemand,
  deliveryType,
  standardProps,
  onDemandProps,
  selectedVehicle,
  courier,
  hasAddress,
  courierMode,
  productWeightAndDimension,
  itemType,
  handleSelectCourier,
  isFilledUpFields,
  itemApproxWeight,
  courierRate,
  hasFilled,
  user,
  hasDropOffAddress,
  pickUp,
  dropOff,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showScheduleDrawer, setScheduleDrawer] = useState(false);
  const [showItemDetailsDrawer, setItemDetailsDrawer] = useState(false);

  const appAccess = useSelector(state => state.appAccessReducer);
  const { view, formTab, prevRoute, targetOrder } = useSelector(state => state.homeReducer);

  const pickUpLaterDisplay = `${moment(onDemandProps.pickupDateTime).format(DAY_MMM_D_HH_MM)}`;
  const isPickUpNow = onDemandProps.pickUpTime === 'PickUpNow' || onDemandProps.pickUpTime === '';
  const prevCourierMode = !(courierMode === 'regular');

  const delay = (fn, ms) => setTimeout(fn, ms);
  useEffect(() => {
    const isOndemandView = isOndemand;
    
    const addressValidation = isValid(
      !isEmpty(targetOrder)
        ? [targetOrder?.pickupAddress, targetOrder?.deliveryDetails?.deliveryAddress]
        : [pickUp?.senderDetails?.address, dropOff?.recipientDetails?.address]
    );
    if (isEmpty(prevRoute)) return;

    const navigateToProductList = () =>
      navigatePage(MODULE_PATH.STANDARD.PRODUCT_LIST, { state: { view } });
    const navigateToVehiclePage = () =>
      navigatePage(MODULE_PATH.SHARED.VEHICLE, { state: { view } });

    delay(async () => {
      dispatch(setPrevRoute(''));
      switch (prevRoute) {
        case 'ViewAll':
          if (addressValidation) {
            isOndemandView ? handleScheduler(true) : navigateToProductList();
            break;
          }
          break;

        case 'DropOff':
        case 'PickUp':
          if (addressValidation) {
            if (isOndemandView) {
              if (onDemandProps?.defaultTimeTag) {
                handleScheduler(true);
                break;
              }
            } else {
              if (standardProps.product?.default) {
                let productObj = { ...standardProps.product };
                delete productObj.default;
                dispatch(setProduct(productObj));
                navigateToProductList();
                break;
              }
            }
          }
          break;
        case 'PickUpNowLater':
          if (isOndemandView && selectedVehicle?.default) {
            let vehicleObj = { ...selectedVehicle };
            delete vehicleObj.default;
            dispatch(setVehicleType(vehicleObj));
            navigateToVehiclePage();
          }
          break;
        case 'vehicleType':
        case 'productSize':
          if (isEmpty(itemType)) {
            setItemDetailsDrawer(true);
            break;
          }

          if (isEmpty(courier)) {
            const vehicleChanged = prevRoute === 'vehicleType' && location?.state?.isVehicleChange;
            const productChanged =
              prevRoute === 'productSize' && location?.state?.isProductSizeChange;

            if (vehicleChanged || productChanged) {
              handleSelectCourier();
              break;
            }
          }
          break;
        case 'itemDetails':
          if (isOndemandView) {
            if (selectedVehicle?.default) {
              let vehicleObj = { ...selectedVehicle };
              delete vehicleObj.default;
              dispatch(setVehicleType(vehicleObj));
              navigateToVehiclePage();
              return;
            }
            if (isEmpty(courier)) {
              handleSelectCourier();
              return;
            }
          } else {
            if (standardProps.product?.default) {
              let productObj = { ...standardProps.product };
              delete productObj.default;
              dispatch(setProduct(productObj));
              navigateToProductList();
              return;
            }
            if (isEmpty(courier)) {
              handleSelectCourier();
              return;
            }
          }
          break;
        case 'couriersList':
          break;

        default:
          break;
      }
    }, 500);
  }, [prevRoute, onDemandProps, standardProps, formTab]);

  const handleScheduler = bool => {
    return setScheduleDrawer(bool);
  };

  const handleVehicleIcon = value => {
    let vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return ON_DEMAND_ICON[vehicleIcon];
  };

  const ProductIcon = value => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return STANDARD_ICON[productIcon];
  };

  const courierInfoRate = () => {
    if (!isEmpty(courier) && hasAddress) {
      return `P ${formatBills(
        parseFloat(courierRate) +
          (courier === BOOK_ANY ? getConvenienceFee().BROADCAST : getConvenienceFee().REGULAR)
      )}`;
    }
    if (prevCourierMode && hasAddress && isEmpty(courier)) {
      return <LoaderSpinner />;
    }
    return;
  };

  const courierInfoLabel = () => {
    if (!isEmpty(courier) && hasAddress) {
      return capitalizeName(courier === FOOD_PANDA ? PANDAGO : courier.toLowerCase());
    }
    if (prevCourierMode && hasAddress) {
      return BOOK_ANY;
    }
    return 'Courier descriptions';
  };

  const courierImage =
    courier === BOOK_ANY ? ICON.BOOK_ANY : COURIER_IMAGES[courier?.toUpperCase()];

  const refs = {
    refDeliveryType: useRef(null),
    refPickUpDropOff: useRef(null),
    refPickNowLater: useRef(null),
    refVehicleType: useRef(null),
    refProductSize: useRef(null),
    refItemDetails: useRef(null),
    refCourierList: useRef(null),
  };

  const navigatePage = (path, state) => {
    // if (typeof appAccess.tourIdx === 'boolean') return;
    return navigate(path, state);
  };

  const CardDescription = ({ imageName, rest }) => {
    return (
      <span className='cntnt-small-caption'>
        {imageName === 'Motorcycle' && !isEmpty(selectedVehicle) && (
          <img src={kgImage} alt={imageName} />
        )}
        {imageName === 'Item' && itemType && <img src={approxWeight} alt={imageName} />}
        {` ${rest.description} ${imageName === 'Item' && itemType ? 'Kg(s)' : ''}`}
      </span>
    );
  };

  const FieldSelection = ({ className, imageName, refIdx, ...rest }) => {
    const isFieldRequired = ['Item', 'Courier'].includes(imageName);
    const requiredCharIndicator = isFieldRequired ? '*' : '';
    return (
      <div
        className={`field-container ${className} try`}
        ref={refIdx === 'refProductSize' ? null : refs[refIdx]}
      >
        <div className={className} onClick={rest.onClick}>
          <img src={rest.imageSrc} alt={imageName} />
          <div className="title">
            {imageName === 'Courier' && !isEmpty(courier) ? (
              <span className="has-courier lbl-base">{rest.description}</span>
            ) : (
              <>
                {imageName === 'Product size' && !isEmpty(standardProps?.product) ? (
                  <span className='lbl-base'>
                    {`Product size`}
                    <span className="title-emphasize cntnt-small-caption">({rest.title})</span>
                    <span>{requiredCharIndicator}</span>
                  </span>
                ) : (
                  <span className='lbl-base'>
                    {rest.title} <span>{requiredCharIndicator}</span>
                  </span>
                )}
                <CardDescription imageName={imageName} rest={rest} />
              </>
            )}
          </div>
          {imageName === 'Courier' && <div className="price">{courierInfoRate()}</div>}
          <img src={arrowRight} alt="arrow-right" />
        </div>
      </div>
    );
  };

  const isValid = arr => {
    const isTrue = [...arr].every(value => !isEmpty(value));
    return isTrue;
  };

  return (
    <div className="booking-fields-card">
      <div className="fields v3">
        <FieldSelection
          className={`pickup-now ${deliveryType}`}
          imageName="Pickup Now"
          imageSrc={isPickUpNow ? PickupNow : PickupLater}
          title={isPickUpNow ? 'Pickup now' : 'Pickup later '}
          description={isPickUpNow ? 'Pickup your item as soon as possible' : pickUpLaterDisplay}
          onClick={() => {
            handleScheduler(true);
          }}
          refIdx="refPickNowLater"
        />
        {isOndemand && (
          <FieldSelection
            className={`motorcycle ${deliveryType}`}
            imageName="Motorcycle"
            imageSrc={handleVehicleIcon(selectedVehicle.vehicleType)}
            onClick={() => {
              navigatePage(MODULE_PATH.SHARED.VEHICLE, { state: { view: view } });
            }}
            title={
              isOndemand
                ? selectedVehicle?.vehicleType === 'MPV'
                  ? selectedVehicle?.vehicleType
                  : formatUnderscoredText(selectedVehicle.vehicleType)
                : ''
            }
            description={`${(isOndemand && selectedVehicle?.description?.dimensionText) || ''} • ${
              (isOndemand && selectedVehicle?.description?.weightText) || ''
            }`}
            refIdx="refVehicleType"
          />
        )}
        <FieldSelection
          className={`product-size ${deliveryType}`}
          imageName="Product size"
          imageSrc={ProductIcon(standardProps?.product?.value || '')}
          onClick={() => {
            navigatePage(MODULE_PATH.STANDARD.PRODUCT_LIST, { state: { view: view } });
          }}
          title={standardProps?.product?.value}
          description={productWeightAndDimension}
        />
        <FieldSelection
          className="item-details"
          imageName="Item"
          imageSrc={IconItemDetails}
          title={capitalizeName(itemType) || 'Add item details'}
          description={itemType ? parseFloat(itemApproxWeight) : 'Item description'}
          refIdx="refItemDetails"
          onClick={() => {
            setItemDetailsDrawer(true);
          }}
        />
        <FieldSelection
          className="find-courier"
          imageName="Courier"
          imageSrc={isEmpty(courier) ? IconFindCourier : courierImage}
          title="Find a courier"
          description={courierInfoLabel()}
          refId="ref-courier-list"
          onClick={() => {
            handleSelectCourier();
          }}
          refIdx="refCourierList"
        />
      </div>
      <Schedule
        show={showScheduleDrawer}
        setScheduleDrawer={() => {
          handleScheduler(false);
        }}
        onSetVehicle={() => {
          if (!user?.tour?.touredForm) return;
        }}
      />
      <ItemDetailsList
        show={showItemDetailsDrawer}
        onCloseItemDetailsDrawer={() => setItemDetailsDrawer(false)}
      />
    </div>
  );
}

export default BookingFields;
