import React from 'react';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';

const Vouchers = ({
  from,
  logo,
  voucherIcon,
  isSelected = false,
  onSelect,
  description,
  expirationLeft,
  amountOff,
  voucherData,
  searchVoucherInput,
  selectedVoucher,
  prevPath,
  index,
}) => {
  const navigate = useNavigate();
  const isFromTC = from === 'voucher-tc';
  const isFromVoucherCenter = from === 'voucher-center';
  const isSelectVoucher = from === 'select-voucher';
  const selected = isSelected === true && 'selected';
  const seachedVoucherCode = searchVoucherInput ? 'searched-voucher' : 'no-searched';

  const justifyStyle = isFromTC ? 'justify-start' : 'justify-center';
  const gapStyle = isFromVoucherCenter ? 'gap-35px' : 'gap-20px';

  const isDisabled = selectedVoucher != null && selectedVoucher !== index;

  const voucherEnabledOrDisabledStyle = {
    pointerEvents: isDisabled ? 'none' : 'auto',
    opacity: isDisabled ? 0.5 : 1,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
  };

  const CircleIcon = () => {
    if (isSelected) {
      return <img src={ICON.CHECKED_CIRCLE} alt="Checked Circle" />;
    }

    return <img src={ICON.UNCHECK_CIRCLE} alt="Unchecked Circle" />;
  };

  return (
    <div
      className={`voucher__container ${from} ${selected} ${seachedVoucherCode}`}
      style={voucherEnabledOrDisabledStyle}
      key={index}
      onClick={isSelectVoucher ? () => onSelect(voucherData, index) : undefined}
    >
      <div className={`voucher-card flex-row items-center ${justifyStyle} ${gapStyle}`}>
        <div className="description flex-col gap-8px justify-start">
          <div className="promo-lable flex-row gap-8px items-center justify-start">
            <img src={voucherIcon} alt="Voucher icon" />
            <span className="hdln-block">{amountOff}</span>
          </div>
          <div className="promo-description">{description}</div>
          <span className="expiration">
            {`expiring: ${expirationLeft}`}
            <span
              className="text-primary"
              onClick={e => {
                e.stopPropagation();
                navigate(MODULE_PATH.EXTRAS.VOUCHER_TC, {
                  state: { link: prevPath, voucherData: voucherData },
                });
              }}
            >
              T&C
            </span>
          </span>
        </div>
        {from === 'select-voucher' && <CircleIcon />}
      </div>
    </div>
  );
};

export default Vouchers;
