import axios from 'axios';
import { ref, set, get } from 'firebase/database';
import { realtimeDb } from '../helpers/firebaseUtils';

import { getServerTime, isEmpty } from '../helpers/purefunctions';
import { API_CONFIG } from '../../config/app';

class Login {
  constructor() {
    this.customerPath = `CLIENTS/GCASH/PARCELS_USERS`;
    this.gcashAuthPath = `CLIENTS/GCASH/AUTH`;
    this.AUTHORIZATION = '163e3ebd6ea01de274904864e42e2776';
    this.API = API_CONFIG.PARCELS_API;
  }

  async getAuthCode(authCode) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/authorization`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data: {
          authCode,
        },
      });

      return response.data;
    } catch (error) {
      const resultErr = { message: error?.message, success: false, error };
      return resultErr;
    }
  }

  async getUsersInfo(authCode) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/userInfo/getByUserId`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data: {
          userId: authCode,
        },
      });

      return response.data;
    } catch (error) {
      const resultErr = { message: error?.message, success: false, error };

      return resultErr;
    }
  }

  async updateUsersInfo(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/userInfo/updateByUserId`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return response.data;
    } catch (error) {
      const resultErr = { message: error?.message, success: false, error };

      return resultErr;
    }
  }

  async getUsersInfo(authCode) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/userInfo/getByUserId`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data: {
          userId: authCode,
        },
      });

      return response.data;
    } catch (error) {
      const resultErr = { message: error?.message, success: false, error };

      return resultErr;
    }
  }

  async updateUsersInfo(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/userInfo/updateByUserId`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return response.data;
    } catch (error) {
      const resultErr = { message: error?.message, success: false, error };

      return resultErr;
    }
  }

  async saveUser(userId, userInfo, systemInfo = {}) {
    const db = realtimeDb;
    let gcashNumber = '';
    let email = '';
    let firstName = '';
    let lastName = '';

    // v1
    if (userInfo?.extendInfo) {
      let extendInfo = JSON.parse(userInfo.extendInfo) || {};
      const { EMAIL_ADDRESS = '', GCASH_NUMBER = '' } = extendInfo;
      const userName = userInfo?.userName || {};

      gcashNumber = String(GCASH_NUMBER);
      email = String(EMAIL_ADDRESS);
      firstName = String(userName?.firstName || '');
      lastName = String(userName?.lastName || '');
    }

    // v2
    if (userInfo?.basicInfo) {
      const basicInfo = userInfo.basicInfo;
      gcashNumber = String(basicInfo?.mobileNumber || '');
      email = String(basicInfo?.emailAddress || '');
      firstName = String(basicInfo?.firstName || '');
      lastName = String(basicInfo?.lastName || '');
    }

    if (userInfo?.currentUser) {
      const basicInfo = userInfo.currentUser;
      gcashNumber = String(basicInfo?.gcashNumber || '');
      email = String(basicInfo?.email || '');
      firstName = String(basicInfo?.firstName || '');
      lastName = String(basicInfo?.lastName || '');
    }

    const loggedIn = await getServerTime();

    let userSystemInfo;
    if (!isEmpty(systemInfo)) {
      userSystemInfo = {
        app: systemInfo.app,
        brand: systemInfo.brand,
        model: systemInfo.model,
        platform: systemInfo.platform,
        system: systemInfo.system,
      };
    }

    let defaultUser = {
      firstName,
      lastName,
      gcashNumber,
      email,
      id: userId,
      loggedIn,
      advancedCourierMode: false,
      recordCreated: false,
      courierMode: 'regular',
      tour: {
        disWelcome: false,
        touredForm: false,
        touredLegend: false,
        touredViewAll: false,
      },
      userType: 'NEW',
      userSystemInfo,
    };

    const userRef = ref(db, `${this.customerPath}/${userId}`);
    const snapshot = await get(userRef);
    let mergedUser;

    if (snapshot.exists()) {
      const existingData = snapshot.val();
      mergedUser = {
        ...defaultUser,
        ...existingData,
        tour: {
          disWelcome: existingData?.tour?.disWelcome || existingData?.disWelcome || false,
          touredForm: existingData?.tour?.touredForm || existingData?.touredForm || false,
          touredLegend: existingData?.tour?.touredLegend || existingData?.touredLegend || false,
          touredViewAll: existingData?.tour?.touredViewAll || existingData?.touredViewAll || false,
        },
        touredForm: null,
        touredLegend: null,
        touredViewAll: null,
        lastLoggedIn: existingData?.loggedIn ?? loggedIn,
      };
    } else {
      mergedUser = { ...defaultUser, lastLoggedIn: loggedIn };
    }

    this.updateUser(userId, mergedUser);

    defaultUser = { ...mergedUser };

    delete defaultUser?.touredForm;
    delete defaultUser?.touredLegend;
    delete defaultUser?.touredViewAll;

    return defaultUser;
  }

  async updateUser(userId, userInfo) {
    const db = realtimeDb;
    const userRef = ref(db, `${this.customerPath}/${userId}`);

    try {
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        await set(userRef, userInfo);
      } else {
        await set(userRef, userInfo);
      }
    } catch (error) {
      // console.error('Error details:', error);
    }
  }
}

export default Login;
