import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import MAIL from '../../../../shared/assets/svg/mail.svg'
import TICKET from '../../../../shared/assets/svg/ticket.svg'
import {
  copyToClipBoard,
  showConfirmationModal,
} from '../../../../shared/utils/helpers/purefunctions';
import { SUPPORT_CONTACTS } from '../../../../shared/utils/enums/AppConstants';

import Footer from '../../v4/Home/components/Footer';

const HelpCenter = () => {
  const { lastmileEmail, submitTicket,  helpCenter, chatSupportLink } = SUPPORT_CONTACTS;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const prevNavigate = {
    label: 'Choose Vehicle Type',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };
  const handleOpenLink = url => {
    showConfirmationModal(url);
  };

  return (
    <div className="help-center-container">
      <StickyHeader title="Help Center" onPrevNavigate={prevNavigate} />
      <div className='card'>
        <span className='title hdln-block'>How can we help you?</span>
        <span className='description cntnt-caption'>Report your concerns by leaving a message and we’ll respond as soon as we can.</span>
        <div className='help-mail' onClick={() => copyToClipBoard(lastmileEmail)}>
          <img src={MAIL} />
          <span className='cntnt-caption-bold'>help@lastmile.ph</span>
        </div>
        <span className='help-concern cntnt-caption'>You can also talk with out customer support to ask or follow-up on your concerns</span>
        <div className='chat-live-support' onClick={() => handleOpenLink(chatSupportLink)}><span className='lbl-large'>Chat with Live Support</span></div>
        <div className='visit-help-center' onClick={() => handleOpenLink(helpCenter)}><span className='lbl-large'>Visit our Help Center</span></div>
      </div>
      <div className='card'>
        <span className='title hdln-block'>Your Tickets</span>
        <div className='follow-updates'>
          <img src={TICKET} />
          <span className='cntnt-caption'>You can follow up and get updates on your active tickets here.</span>
        </div>
        <div className='chat-live-support' onClick={() => handleOpenLink(submitTicket)}><span className='lbl-large'>Submit a ticket</span></div>
      </div>
      <Footer />
    </div>
  );
};

export default HelpCenter;
