import { COURIER_LOGO } from './AppIcon';

export const ENUM = {
  HEADER: {
    welcome_title: 'Hi {name}, Welcome to Parcels',
    text: {
      search: 'Search',
      orders: 'Orders',
    },
  },
  COURIERS_LIST: {
    couriers_logo: [
      {
        logo: COURIER_LOGO.LALAMOVE,
        label: 'Lalamove',
        type: 'onDemand',
        status: 'active',
        id: 'LALAMOVE',
      },
      {
        logo: COURIER_LOGO.PANDAGO,
        label: 'PandaGo',
        type: 'onDemand',
        status: 'active',
        id: 'FOOD_PANDA',
      },
      { logo: COURIER_LOGO.XDE, label: 'XDE', type: 'standard', status: 'active', id: 'XDE' },
      {
        logo: COURIER_LOGO.NINJAVAN,
        label: 'NINJAVAN',
        type: 'standard',
        status: 'active',
        id: 'NINJAVAN',
      },
      { logo: COURIER_LOGO.QUADX, label: 'QUADX', type: 'standard', status: 'active', id: 'QUADX' },
      { logo: COURIER_LOGO.GRAB, label: 'Grab', type: 'onDemand', status: 'active', id: 'GRAB' },
      { logo: COURIER_LOGO.JNT, label: 'J&T', type: 'standard', status: 'inactive', id: 'J&T' },
      { logo: COURIER_LOGO.ALLCOURIER, label: 'All', type: 'none' },
    ],
    product_size: 'Product Size',
    courier_label: 'Courier',
    pickup_now_title: 'Pickup and deliver now!',
    pickup_later_title: 'Deliver at a later date',
    error_label: 'Please update the address or select another courier.',
  },
  FORM: {
    header_title: 'Book Order',
    similar_couriers: 'Similar Couriers',
    book_any_description:
      'Enable this option to book both services simultaneosly and get you delivery faster with the first to arrive!',
  },
};
