import ADDRESSBOOK from '../../v4/assets/svg/referral/v4_addressBook.svg';
import COPY from '../../v4/assets/svg/referral/v4_copy.svg';
import POLICY from '../../v4/assets/svg/referral/v4_policy.svg';
import REFERRAL from '../../v4/assets/svg/referral/v4_referral.svg';
import BANNER from '../../v4/assets/svg/referral/v4_banner_.png';
// import BANNER from '../../v4/assets/svg/referral/v4_referralBanner.svg';
import REWARDS from '../../v4/assets/svg/referral/v4_rewards.svg';
import SOON from '../../v4/assets/svg/referral/v4_soon.svg';
import STAR from '../../v4/assets/svg/referral/v4_star.svg';
import TNC from '../../v4/assets/svg/referral/v4_tnc.svg';
import TROPHY from '../../v4/assets/svg/referral/v4_trophy.svg';
import LMI from '../../v4/assets/svg/referral/v4_lmi.svg';
import PARCELS from '../../v4/assets/svg/referral/v4_parcels.svg';

export const V4_ICONS = {
    ADDRESSBOOK,
    COPY,
    POLICY,
    REFERRAL,
    BANNER,
    REWARDS,
    SOON,
    STAR,
    TNC,
    TROPHY,
    LMI,
    PARCELS
};