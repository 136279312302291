import axios from 'axios';
import { VOUCHER_ERROR_MESSAGE } from '../enums/Messages';
import { API_CONFIG } from '../../config/app';

class ReferralDAO {
  constructor() {
    this.API = API_CONFIG.PARCELS_API;
    this.AUTHORIZATION = API_CONFIG.PARCELS_AUTH;
    this.AUTHORIZATION_GET_METHOD = API_CONFIG.PARCELS_GET_AUTH;
  }

  async getReferralOffer(params) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/setup/get`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getReferralCode(params) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/referral/getByUserId`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getReferralCodeUsage(params) {
    try {
      console.log('getReferralCodeUsage: ', {
        method: 'GET',
        url: `${this.API}/v1/referral/getReferralCodeUsage`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
        params,
      });
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/referral/getReferralCodeUsage`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
        params,
      });
      console.log('response.data: ', response.data);
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }
}

export default ReferralDAO;
