import React, { createContext, useContext } from 'react';
import { useVoucherCenter } from '../hooks/useVoucherCenter';

const VoucherContext = createContext();

export const VoucherCenterProvider = ({ userType, children }) => {
  const { data, isLoading, error } = useVoucherCenter(userType);

  return (
    <VoucherContext.Provider value={{ data, isLoading, error }}>{children}</VoucherContext.Provider>
  );
};

export const useVoucherCenterContext = () => useContext(VoucherContext);
