import { Card } from 'antd';
import React from 'react';

const VoucherCardLoader = ({ count }) => {
  return (
    <>
      {Array.from({ length: count }, (_, i) => (
        <Card
          loading={true}
          style={{
            minWidth: 250,
            height: 122,
          }}
          key={i}
        ></Card>
      ))}
    </>
  );
};

export default VoucherCardLoader;
