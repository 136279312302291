import React from 'react';
import LoaderSpinner from '../LoaderSpinner';

const CheckingLoader = () => {
  return (
    <div className="checking__loader">
      <LoaderSpinner size="24px" />
      <span>Checking...</span>
    </div>
  );
};

export default CheckingLoader;
