import React, { memo, useEffect, useState } from 'react';

const TypingEffect = ({ text, speed = 200, delay = 1000 }) => {
  const [displayText, setDisplayText] = useState('');
  const [charIndex, setCharIndex] = useState(0);
  const [isWordComplete, setIsWordComplete] = useState(false);

  useEffect(() => {
    let timer;

    if (charIndex < text.length) {
      timer = setTimeout(() => {
        setDisplayText(prev => prev + text[charIndex]);
        setCharIndex(charIndex + 1);
      }, speed);
    } else if (charIndex === text.length) {
      timer = setTimeout(() => {
        setDisplayText('');
        setCharIndex(0);
        setIsWordComplete(false);
      }, delay);
    }

    if (charIndex === 6) {
      setIsWordComplete(true);
    }
    return () => clearTimeout(timer);
  }, [charIndex, text, speed, delay]);

  return (
    <div className={`typing__container ${isWordComplete ? 'word-complete' : ''}`}>
      {displayText.split('').map((char, idx) => (
        <span key={idx} className="fade-in">
          {char}
        </span>
      ))}
    </div>
  );
};

export default memo(TypingEffect);
