import React, { memo, useMemo } from 'react';
import VoucherCardLoader from '../../../../../shared/components/VoucherCardLoader';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';
import moment from 'moment-timezone';
import Vouchers from '../../../Home/v3/components/Vouchers';
import { getAmountOff, getTimeLeft } from '../../../../../shared/utils/helpers/purefunctions';

const VoucherLists = ({ data, isLoading, userType, fromForm }) => {
  const latestThreeVoucher = useMemo(() => {
    if (!data) return [];
    return data.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))).slice(0, 3);
  }, [data]);

  return (
    <div className={`vouchers flex-col ${fromForm}`}>
      <div className="title flex-row items-center gap-16px">
        <span className="hdln-block">Vouchers</span>
        <img src={ICON.ARROW_RIGHT} alt="arrow right" />
      </div>

      <div className="home__voucher flex-row gap-16px">
        {isLoading || !userType ? (
          <VoucherCardLoader count={2} />
        ) : (
          data &&
          latestThreeVoucher.map((data, index) => {
            const expirationLeft = getTimeLeft(data.expiration);
            const amountOff = getAmountOff(data);
            return (
              <Vouchers
                key={index}
                expirationLeft={expirationLeft}
                description={data.description}
                voucherData={data}
                amountOff={amountOff}
                from="home"
                logo={ICON.WHITE_PARCELS_LOGO}
                voucherIcon={ICON.VOUCHER_CARD_ICON_GREEN}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default memo(VoucherLists);
