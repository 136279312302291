const SEPARATORS = {
  Slash: '/',
  Dash: '-',
  Space: ' ',
  Period: '.',
};

const createDateOrder = separator => {
  return [
    { format: `MM${separator}DD`, formatYear: `MM${separator}DD${separator}YYYY` },
    { format: `DD${separator}MM`, formatYear: `DD${separator}MM${separator}YYYY` },
    { format: `MM${separator}DD`, formatYear: `YYYY${separator}MM${separator}DD` },
    { format: `MMM${separator}DD`, formatYear: `MMM${separator}DD${separator}YYYY` },
    { format: `DD${separator}MMM`, formatYear: `DD${separator}MMM${separator}YYYY` },
    { format: `MMM${separator}DD`, formatYear: `YYYY${separator}MMM${separator}DD` },
  ];
};

const DateFormatsFactory = () => {
  const formatsObj = {};
  for (const char in SEPARATORS) {
    formatsObj[char] = createDateOrder(SEPARATORS[char]);
  }
  return formatsObj;
};

export const DAY_FMT = 'dddd';
export const KEEN_DATE = 'YYYY-MM-DD';
export const DEFAULT_DATE = 'MM-DD-YYYY';
export const TIME_FMT = 'hh:mm a';
export const TIME_FMT_SECS = 'hh:mm:ss A';
export const DATE_FORMATS = DateFormatsFactory();
export const YYYY_MM_DDTHH_MM_SS = 'YYYY-MM-DDTHH:mm:ss';
export const YYYY_MM_DDTHH_MM = 'YYYY-MM-DDTHH:mm';
export const HH_MM = 'HH:mm';
export const NOTES_DATE_FORMAT = `MMMM DD, YYYY ${TIME_FMT}`;
export const REFERRAL_DATE_FORMAT = `MMMM DD, YYYY hh:mm A`;
export const WRITTEN_DATE_FORMAT = 'MMMM DD, YYYY';
export const MMM_DD = 'MMM DD';
export const MM_DD_YYYY = 'MM-DD-YYYY';
export const EXCEL_DOWNLOAD_FORMAT = 'MM/DD/YYYY';
export const DAY_FMT_DDD = 'ddd';

export const DAY_MMM_D_HH_MM = 'ddd, MMM D, h:mm A';
export const DAY_MMM_D = 'ddd, MMM D';
export const YYYY_MM_DD_H_MM_A = 'YYYY-MM-DD h:mm a';
export const CAPS_YYYY_MM_DD_H_MM_A = 'YYYY-MM-DD h:mm A';
export const YYYY_MM_DD_HH_MM = 'YYYY-MM-DD hh:mm';
export const DAY_MMM_D_YYYY = 'ddd, MMM D, YYYY';
export const H_MM_A = 'h:mm A';
export const H_MM_a = 'h:mm a';
export const DD_MMM_YYYY_HH_MM = 'DD MMM YYYY HH:mm';
