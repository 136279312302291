import React from 'react';
import { ICON } from '../../../../../../shared/utils/enums/v4/AppIcon';
import { ENUM } from '../../../../../../shared/utils/enums/v4/AppConstants';

const ErrorSelectedCourier = ({ errorMessage }) => {
  return (
    <div className="error__courier">
      <img src={ICON.ERROR} alt="Error" />
      <div className="error-message">
        <span>{errorMessage}.</span>
        <span>{ENUM.COURIERS_LIST.error_label}</span>
      </div>
    </div>
  );
};

export default ErrorSelectedCourier;
