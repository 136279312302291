import axios from 'axios';
import { VOUCHER_ERROR_MESSAGE } from '../enums/Messages';
import { API_CONFIG } from '../../config/app';

class PaymentDAO {
  constructor() {
    this.API = API_CONFIG.PARCELS_API;
    this.AUTHORIZATION = API_CONFIG.PARCELS_AUTH;
    this.AUTHORIZATION_GET_METHOD = API_CONFIG.PARCELS_GET_AUTH;
  }

  async gcashPayment(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/payment`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });
      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async gcashPaymentInquiry(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/payment/inquiry`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getTransaction(data) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/transaction`,
        params: { userId: data },
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
      });

      return {
        success: true,
        result: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async pushTransaction(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/transaction`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async refund(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/refund`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async testPayload(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/test/payload`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getCashBack(params) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/parcelsCashback/get`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
        params,
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getCashBackTransactions(userId) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/parcelsCashback/getTransaction/?userId=${userId}`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
      });
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async inquireVoucher(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/voucher/inquireCode`,
        data,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
      });
      return response?.data;
    } catch (error) {
      if (error.response?.status === 400) {
        return error.response?.data;
      }
      return {
        success: false,
        message: VOUCHER_ERROR_MESSAGE,
      };
    }
  }

  async getVoucherCenter(userType) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/voucher/voucherCenter?userType=${userType}`,
        headers: {
          Authorization: `Basic ${this.AUTHORIZATION_GET_METHOD}`,
        },
      });
      return response?.data;
    } catch (error) {
      if (error.response?.status === 400) {
        return error.response?.data;
      }
      return {
        success: false,
        message: VOUCHER_ERROR_MESSAGE,
      };
    }
  }

  async getFailedBookings() {
    try {
      const response = await axios({
        method: 'GET',
        url: `${API_CONFIG.PARCELS_API}/v1/booking/failedBooking`,
        headers: {
          Authorization: `Bearer ${this.AUTHORIZATION_GET_METHOD}`,
        },
      });
      return response?.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }
}

export default PaymentDAO;
