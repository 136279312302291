import DEFAULT from '../../assets/images/Couriers Images/On-demand Couriers/Courier-no-icon.png';
import BORZO from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import MRSPEEDY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import GRAB from '../../assets/images/Couriers Images/On-demand Couriers/Grab.svg';
import LALAMOVE from '../../assets/images/Couriers Images/On-demand Couriers/Lalamove.svg';
import FOOD_PANDA from '../../assets/images/Couriers Images/On-demand Couriers/Pandago.svg';
import TRANSPORTIFY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Transportify.png';
import NINJAVAN from '../../assets/images/Couriers Images/On-demand Couriers/NinjaVan.svg';
import QUADX from '../../assets/images/Couriers Images/On-demand Couriers/QuadX.svg';
import XDE from '../../assets/images/Couriers Images/Standard Couriers/2x/XDE.png';
import CLIQNSHIP from '../../assets/images/Couriers Images/Standard Couriers/2x/Cliqnship.png';

import LogoNinjavan from '../../assets/svg/home-booking/ninja_van_logo.svg';
import LogoXde from '../../assets/svg/home-booking/xde_logo.svg';
import LogoQuadX from '../../assets/svg/home-booking/quadx_logo.svg';
import LogoPandago from '../../assets/svg/home-booking/pandaGo_logo.svg';
import LogoLalamove from '../../assets/svg/home-booking/lalamove_logo.svg';
import LogoGrab from '../../assets/svg/home-booking/grab_logo.svg';

export const COURIER_IMAGES = {
  MRSPEEDY,
  BORZO,
  GRAB,
  LALAMOVE,
  TRANSPORTIFY,
  NINJAVAN,
  QUADX,
  XDE,
  CLIQNSHIP,
  DEFAULT,
  FOOD_PANDA,
};

export const COURIER_LOGO = {
  lalamove: LogoLalamove,
  pandago: LogoPandago,
  grab: LogoGrab,
  ninjavan: LogoNinjavan,
  quadx: LogoQuadX,
  xde: LogoXde,
};
