import SetupDAO from '../../utils/dao/SetupDAO';

import {
  SET_APP_ACCESS,
  SET_APP_DISCLAIMER,
  SET_APP_DISCOVER,
  SET_APP_TOUR_IDX,
  SET_APP_SHOW_TOAST,
  SET_APP_LEGEND,
  SET_APP_VIEW_ALL,
  SET_APP_INFO,
  SET_FLAGS,
  SET_SETUP,
} from '../enums/types';

export const setAppAccess = appAccess => ({
  type: SET_APP_ACCESS,
  payload: appAccess,
});

export const setAppDisclaimer = disclaimer => ({
  type: SET_APP_DISCLAIMER,
  payload: disclaimer,
});

export const setAppLegend = legend => ({
  type: SET_APP_LEGEND,
  payload: legend,
});

export const setAppViewAll = viewAll => ({
  type: SET_APP_VIEW_ALL,
  payload: viewAll,
});

export const setAppDiscover = discover => ({
  type: SET_APP_DISCOVER,
  payload: discover,
});
export const setAppTourIdx = tourIdx => ({
  type: SET_APP_TOUR_IDX,
  payload: tourIdx,
});

export const setShowedToast = showedToast => ({
  type: SET_APP_SHOW_TOAST,
  payload: showedToast,
});

export const setAppInfo = appInfo => ({
  type: SET_APP_INFO,
  payload: appInfo,
});

export const setFlags = userFlags => ({
  type: SET_FLAGS,
  payload: userFlags,
});

export const setSetup = setup => ({
  type: SET_SETUP,
  payload: setup,
});

export const fetchSetup = () => {
  return async (dispatch, getState) => {

    const setupDao = new SetupDAO();
    const setUpList = await setupDao.getSetupList({page: 1, pageSize: 100});
    dispatch(
      setSetup(setUpList?.success ? setUpList?.result : [])
    );
  };
};