import produce from 'immer';
import { SET_REFERRAL_CODE, SET_REFER_REDEMPTIONS, SET_REFERRAL_OFFER, SET_FETCHED } from '../enums/types';

const initialState = {
  referralCode: null,
  referRedemptions: null,
  referralOffer: null,
  fetched: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_REFERRAL_CODE:
        draft.referralCode = action.payload;
        break;
      case SET_REFER_REDEMPTIONS:
        draft.referRedemptions = action.payload;
        break;
      case SET_REFERRAL_OFFER:
        draft.referralOffer = action.payload;
        break;
      case SET_FETCHED:
        draft.fetched = action.payload;
        break;
      default:
        return draft;
    }
  });
