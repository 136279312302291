import React from 'react';
import { Drawer, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import CLOSE_INDICATOR from '../../../../../../shared/v4/assets/svg/default/close_indicator.svg';
import SimilarCouriers from '../Courier/SimilarCouriers';
import { ENUM } from '../../../../../../shared/utils/enums/v4/AppConstants';

const CouriersDrawer = ({ onSetShow, show }) => {
  return (
    <Drawer
      title=""
      placement="bottom"
      height="r50px"
      className="couriers_drawer"
      onClose={() => onSetShow()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => onSetShow()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <img className="close-indicator" src={CLOSE_INDICATOR} />
      <SimilarCouriers orderType="onDemand" title={ENUM.COURIERS_LIST.pickup_now_title} />
      <SimilarCouriers orderType="standard" title={ENUM.COURIERS_LIST.pickup_later_title} />
    </Drawer>
  );
};

export default CouriersDrawer;
