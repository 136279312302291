import React, { useState } from 'react';
import StickyHeader from '../../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import ChevronRight from '../../../../../shared/assets/svg/my-accounts/icon_right.svg';
import IconHelp from '../../../../../shared/assets/svg/my-accounts/icon_help.svg';
import IconTransaction from '../../../../../shared/assets/svg/my-accounts/icon_transactions.svg';
import IconPlus from '../../../../../shared/assets/svg/my-accounts/icon_plus_v2.svg';
import IconLess from '../../../../../shared/assets/svg/my-accounts/icon_less.svg';
import IconCopy from '../../../../../shared/assets/svg/my-accounts/icon_copy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment-timezone';
import {
  CAPS_YYYY_MM_DD_H_MM_A,
  WRITTEN_DATE_FORMAT,
} from '../../../../../shared/utils/enums/dateFormats';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  ABOUT_CASH_BACK,
  CASHBACK_FROM_LABEL,
} from '../../../../../shared/utils/enums/AppConstants';
import { useSelector } from 'react-redux';
import {
  calculateTotalBalance,
  copyToClipBoard,
  isEmpty,
} from '../../../../../shared/utils/helpers/purefunctions';

import Footer from '../../../v4/Home/components/Footer';

const CashBack = () => {
  const [isShowHowModal, setIsShowHowModal] = useState(false);
  const [isShowTransactionModal, setIsShowTransactionModal] = useState(false);
  const [cashbackItemData, setCashbackItemData] = useState({});

  const cashbackTransactions = useSelector(state => state.homeReducer.cashbackTransactions);

  const totalBalance = calculateTotalBalance(cashbackTransactions);

  const prevNavigate = {
    label: '',
    imgPath: '',
    path: MODULE_PATH.HOME,
  };

  const handleShowTransactionModal = data => {
    setCashbackItemData(data);
    setIsShowTransactionModal(true);
  };

  const TransactionLists = () => {
    return (
      <>
        {cashbackTransactions.map((data, index) => {
          const isCashbackFromLess =
            CASHBACK_FROM_LABEL[data.cashbackFrom] === CASHBACK_FROM_LABEL.less;
          const fromLabel = isCashbackFromLess
            ? ''
            : `From ${CASHBACK_FROM_LABEL[data.cashbackFrom]}:`;

          return (
            <div key={index} className="list" onClick={() => handleShowTransactionModal(data)}>
              <div className="description">
                <div className="label mlLbl-small">{`${data.cashbackType} ${data?.remarks ? `- (${data.remarks})` : ''}`}</div>
                <span className='cntnt-fine-caption'>{`${fromLabel} Order No. ${data.orderNo}`}</span>
                <span className='cntnt-fine-caption'>{moment(data.createdAt).format(CAPS_YYYY_MM_DD_H_MM_A)}</span>
              </div>
              <div className="amount mlLbl-small">
                {data.cashbackFrom === 'less' ? (
                  <img src={IconLess} alt="less" />
                ) : (
                  <img src={IconPlus} alt="add" />
                )}
                <FontAwesomeIcon icon={solid('peso-sign')} />
                <span className='mlLbl-small'>{data.amount.toFixed(2)}</span>
                <FontAwesomeIcon icon={solid('ellipsis-h')} />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const HowItWorksModalContent = () => (
    <div className="cashback-content">
      <div className="header">
        <span className="title">Cashback</span>
        <span onClick={() => setIsShowHowModal(false)}>{<CloseOutlined className="close" />}</span>
      </div>
      <div className="about-cashback cntnt-caption">
        <span className="get-info">{ABOUT_CASH_BACK.getting_info}</span>
        <div>
          <span className="title">1. Broadcast On-Demand</span>
          <ul>
            <li>{ABOUT_CASH_BACK.excess_shipping_info}</li>
          </ul>
        </div>
        <div>
          <span className="title"> 2. Vouchers</span>
          <ul>
            <li>{ABOUT_CASH_BACK.stay_tuned_info}</li>
          </ul>
        </div>
      </div>
    </div>
  );

  const TransactionModalContent = () => {
    const isCashbackFromLess =
      CASHBACK_FROM_LABEL[cashbackItemData.cashbackFrom] === CASHBACK_FROM_LABEL.less;
    return (
      <div className="transaction-content">
        <div className="order-info">
          <div className="title lbl-base">Cashback Received from order</div>
          <div className="order-no">
            <span className='lbl-small'>Order No.</span>
            <span className='cntnt-caption-bold'>{cashbackItemData.orderNo}</span>
          </div>
          <div className="date-time">
            <span>Date & Time</span>
            <span>{moment(cashbackItemData.createdAt).format(CAPS_YYYY_MM_DD_H_MM_A)}</span>
          </div>
          <div className="amount">
            <span>Amount</span>
            <div className="amount-info">
              {isCashbackFromLess ? (
                <img src={IconLess} alt="less" />
              ) : (
                <img src={IconPlus} alt="add" />
              )}
              <span>{cashbackItemData.amount.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FotterModalContent = ({ onClose }) => {
    const [_, transactionRefNo] = cashbackItemData.transactionId.split('-');
    return (
      <div className="footer-modal-container">
        <div>
          <span>Reference No.</span>
          <div>
            <span>{transactionRefNo}</span>
            <img src={IconCopy} alt="copy" onClick={() => copyToClipBoard(transactionRefNo)} />
          </div>
        </div>
        <div className="transaction-button" onClick={onClose}>
          Okay
        </div>
      </div>
    );
  };

  const borderBottomStyle = !isEmpty(cashbackTransactions)
    ? { borderBottom: '1px solid #e0e8f3' }
    : { borderBottom: 'none' };

  return (
    <div>
      <StickyHeader title="My Cashback" onPrevNavigate={prevNavigate} />
      <div className="cashback-content">
        <div className="balance">
          <div className="label">
            <span>Balance</span>
            <div className="info">
              <img src={IconHelp} alt="help" />
              <span onClick={() => setIsShowHowModal(true)}>How it works</span>
              <img src={ChevronRight} alt="next" />
            </div>
          </div>
          <div className="available-balance">
            <FontAwesomeIcon icon={solid('peso-sign')} />
            <span>{Number(totalBalance).toFixed(2)}</span>
          </div>
        </div>
        <div className="transations">
          <div className="header" style={borderBottomStyle}>
            <div className="title">
              <img src={IconTransaction} alt="transaction" />
              <span className='hdln-block'>Transactions</span>
            </div>
            <div className="date">
              <span>as of</span> {moment().format(WRITTEN_DATE_FORMAT)}
            </div>
          </div>
          {isEmpty(cashbackTransactions) ? (
            <div style={{ margin: '0 auto', color: '#0a2757' }}>No available transactions.</div>
          ) : (
            <TransactionLists />
          )}
          <Modal
            className="cashback-modal"
            open={isShowHowModal}
            onOk={() => setIsShowHowModal(false)}
            okText="Got it"
            closable={false}
          >
            <HowItWorksModalContent />
          </Modal>
          <Modal
            title="Transaction Details"
            open={isShowTransactionModal}
            onOk={() => setIsShowTransactionModal(false)}
            className="transaction-modal"
            okText="Okay"
            footer={<FotterModalContent onClose={() => setIsShowTransactionModal(false)} />}
            classNames={{ footer: 'footer-transaction' }}
            closable={false}
          >
            <TransactionModalContent />
          </Modal>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CashBack;
