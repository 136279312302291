import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Card, message, Skeleton } from 'antd';

import Form from './components/Form';
import Order from './components/Order';
import Banner from './components/Banner';
import Footer from './components/Footer';
import OrdersCard from '../../../../shared/components/v3/OrdersCard';

import LogoWhite from '../../../../shared/assets/svg/white-logo.svg';
import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { ArrowRightOutlined } from '@ant-design/icons';
import { fetchPendingOrders, setFormTab } from '../../../../shared/redux/home/actions';
import {
  generateOrderId,
  getAmountOff,
  getTimeLeft,
} from '../../../../shared/utils/helpers/purefunctions';
import {
  setOrderId as setOnDemandOrderId,
  setPickUp as setOndemandPickUp,
} from '../../../../shared/redux/onDemand/actions';
import {
  setOrderId as setStandardOrderId,
  setPickUp as setStandardPickUp,
} from '../../../../shared/redux/standard/actions';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import DeliveryTo from './components/DeliveryTo';
import BookingFields from './components/BookingFields';
import FooterBtnHome from '../../../../shared/components/FooterBtnHome';

import { MODULE_PATH, MODULE_ADDRESS } from '../../../../shared/constants/Module';

import { setLoader } from '../../../../shared/redux/home/actions';
import moment from 'moment-timezone';
import Vouchers from './components/Vouchers';
import { useVoucherCenter } from '../../../../shared/hooks/useVoucherCenter';
import VoucherCardLoader from '../../../../shared/components/VoucherCardLoader';

const Home = ({ allDone }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deliveryType, setDeliveryType] = useState('');
  const [isFillUp, setIsFillUp] = useState(false);
  const [courierDrawer, setCourierDrawer] = useState(true);

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const user = useSelector(state => state.usersReducer.currentUser);
  const { fetchAll, fetch } = useSelector(state => state.homeReducer);
  const product = useSelector(state => state.standardReducer.product);
  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);

  const { data, isLoading, error } = useVoucherCenter(userType);

  const latestThreeVoucher =
    data && data.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))).slice(0, 3);

  const { orderList, cancelledOrders, completedOrderList, formTab } = useSelector(
    state => state.homeReducer
  );

  const isOndemand = 'onDemand' === formTab;
  const deliveryProps = isOndemand ? onDemandProps : standardProps;
  const productWeightAndDimension = `${product?.description?.dimensionText} • ${
    product?.description?.weightText || ''
  }`;

  const MODULE_ADDRESS = isOndemand
    ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
    : MODULE_PATH.STANDARD.SENDER_DETAILS;

  const MODULE_COURIER = isOndemand
    ? MODULE_PATH.SHARED.ON_DEMAND.COURIER
    : MODULE_PATH.SHARED.STANDARD.COURIER;

  const MODULE_PAYMENT_SUMMARY = isOndemand
    ? MODULE_PATH.PAYMENT.ON_DEMAND.PAYMENT_SUMMARY
    : MODULE_PATH.PAYMENT.STANDARD.PAYMENT_SUMMARY;

  const prevPath = MODULE_PATH.HOME;

  const {
    pickUp,
    dropOff,
    courier: selectedCourier,
    itemType,
    itemApproxWeight,
    vehicleType: selectedVehicle,
  } = deliveryProps;

  const hasDropOffAddress = isOndemand
    ? onDemandProps.dropOff?.address
    : standardProps.dropOff?.address;

  const hasAddress = !isEmpty(pickUp?.address) && !isEmpty(dropOff?.address);
  const courier = selectedCourier ? selectedCourier.courier : null;
  const courierRate = selectedCourier ? selectedCourier.rate : null;
  const courierMode = user.courierMode;

  const handleSetDeliveryType = type => {
    setDeliveryType(type);
    dispatch(setFormTab(type));
  };

  useEffect(() => {
    dispatch(setLoader(true));
  }, []);

  useEffect(() => {
    if (isEmpty(onDemandProps?.orderId) || isEmpty(standardProps.orderId)) {
      let num = 1;
      const set_OrderId = generateOrderId(num);
      dispatch(setStandardOrderId(set_OrderId));
      dispatch(setOnDemandOrderId(set_OrderId));
    }
    setDeliveryType(formTab);

    const pickupAddress = pickUp?.senderDetails?.address || pickUp.address;
    const dropOffAddress = dropOff?.recipientDetails?.address || dropOff.address;
    setIsFillUp([pickupAddress, dropOffAddress, itemType, courier].every(value => !isEmpty(value)));
  }, [onDemandProps, standardProps]);

  useEffect(() => {
    if (allDone) {
      const pickUpDefAddress = _orderList.length
        ? _orderList
        : completedOrderList.length
        ? completedOrderList
        : cancelledOrders;
      if (pickUpDefAddress.length) {
        const targetOrder = pickUpDefAddress.slice(0, 1)[0];
        const setPickUpAdd = {
          pickupAddress: targetOrder.pickupAddress,
          address: targetOrder.pickupAddress,
          notesToRider: '',
          latitude: targetOrder?.latitude || targetOrder?.lat,
          longitude: targetOrder?.longitude || targetOrder?.lng || targetOrder?.long,
          senderDetails: {
            contactNo: targetOrder.contactNumber,
            fullName: targetOrder.customerName,
            address: targetOrder.pickupAddress,
            province: targetOrder.province,
            city: targetOrder.pickupCity,
            barangay: targetOrder.barangay || 'brgy',
            emailAddress: targetOrder.emailAddress,
          },
        };

        dispatch(setOndemandPickUp(setPickUpAdd));
        dispatch(setStandardPickUp(setPickUpAdd));
      }
    }
  }, [allDone]);

  const standardFillUp =
    !isEmpty(pickUp?.senderDetails?.address) &&
    !isEmpty(dropOff?.recipientDetails?.address) &&
    !isEmpty(itemType) &&
    !isEmpty(courier);

  const hasFilled = [
    pickUp?.senderDetails?.address,
    dropOff?.recipientDetails?.address,
    itemType,
    courier,
  ].some(value => !isEmpty(value));

  const isFilledUpFields = isOndemand ? isFillUp : standardFillUp;

  const handleNavigateAllOrder = () => {
    if (user.recordCreated) {
      navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
    }
  };

  const handleNavigateVoucherCenter = () => {
    navigate(MODULE_PATH.EXTRAS.VOUCHER_CENTER, { state: { path: MODULE_PATH.HOME } });
  };

  const handleSelectCourier = () => {
    if (isEmpty(pickUp?.address) || isEmpty(dropOff?.address)) {
      return message.error('Pickup and Drop-off address are required.');
    }

    return navigate(MODULE_COURIER, {});
  };

  const onRecentlyUsedAll = () => {
    return navigate(MODULE_ADDRESS, {
      state: { showAutoComplete: true, isRecentlyUsed: true, orders: latestCreatedRecords },
    });
  };

  const _orderList = [...orderList];
  const soonestCompletionDates = _orderList
    .sort((a, b) => a.deliveryDetails.completionDateTime - b.deliveryDetails.completionDateTime)
    .slice(0, 3);

  const recentlyOrders = !isEmpty(_orderList)
    ? _orderList
    : !isEmpty(completedOrderList)
    ? completedOrderList
    : cancelledOrders;

  const latestCreatedRecords = [...recentlyOrders]
    .sort((a, b) => a.createdAt - b.createdAt)
    .slice(0, 10);

  const SkeletonLoaders = ({ count }) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <Card
          loading={true}
          style={{
            minWidth: 250,
          }}
        ></Card>
      ))}
    </>
  );

  const LoadingRecentlyOngoing = () => {
    return (
      <div className="orders flex-col">
        <div className="title flex-row items-center gap-16px">
          <span className="hdln-block">
            <Skeleton.Input block={true} active={true} size={'small'} style={{ width: '220px' }} />
          </span>
          <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
        </div>
        <div className="card-loading-wrapper">
          <SkeletonLoaders count={3} />
        </div>
      </div>
    );
  };

  return (
    <div className="home__v3__container flex-col items-center">
      <div className="header flex-row items-center justify-between bg-pink">
        <div className="logo flex-row items-center gap-16px">
          <img src={LogoWhite} alt="Logo" />
          <span className="lbl-large">Welcome to Parcels</span>
        </div>
        <img src={ICON.SEARCH} alt="Search" onClick={handleNavigateAllOrder} />
      </div>
      <div className={`content ${hasDropOffAddress ? 'filling' : ''}`}>
        <Form deliveryType={deliveryType} handleSetDeliveryType={handleSetDeliveryType} />
        {hasDropOffAddress && (
          <BookingFields
            isOndemand={isOndemand}
            deliveryType={deliveryType}
            standardProps={standardProps}
            onDemandProps={onDemandProps}
            selectedVehicle={selectedVehicle}
            hasAddress={hasAddress}
            courier={courier}
            courierMode={courierMode}
            itemType={itemType}
            productWeightAndDimension={productWeightAndDimension}
            isFillUp={isFillUp}
            isFilledUpFields={isFilledUpFields}
            itemApproxWeight={itemApproxWeight}
            handleSelectCourier={handleSelectCourier}
            hasFilled={hasFilled}
            courierRate={courierRate}
            user={user}
            hasDropOffAddress={hasDropOffAddress}
            pickUp={pickUp}
            dropOff={dropOff}
          />
        )}
        {!allDone && !hasDropOffAddress && <LoadingRecentlyOngoing />}
        {allDone && !hasDropOffAddress ? (
          soonestCompletionDates.length ? (
            <div className=" flex-col">
              <div className="title flex-row items-center gap-16px">
                <span className="hdln-block">Ongoing Orders</span>
                <img src={ICON.ARROW_RIGHT} alt="arrow right" onClick={handleNavigateAllOrder} />
              </div>
              <div className="orders-card">
                <OrdersCard isOngoing={true} orders={soonestCompletionDates} />
              </div>
            </div>
          ) : latestCreatedRecords.length ? (
            <div className="orders flex-col">
              <div className="title flex-row items-center gap-16px">
                <span className="hdln-block">Recently Used</span>
                <img src={ICON.ARROW_RIGHT} alt="arrow right" onClick={onRecentlyUsedAll} />
              </div>
              <DeliveryTo orders={latestCreatedRecords} />
            </div>
          ) : null
        ) : null}

        {!hasDropOffAddress && (
          <div className="banner-wrapper">
            <Banner />
          </div>
        )}

        {!hasDropOffAddress && (
          <div className="vouchers flex-col">
            <div className="title flex-row items-center gap-16px">
              <span className="hdln-block">Vouchers</span>
              <img src={ICON.ARROW_RIGHT} alt="arrow right" onClick={handleNavigateVoucherCenter} />
            </div>

            <div className="home__voucher flex-row gap-16px">
              {isLoading
                ? VoucherCardLoader
                : data &&
                  latestThreeVoucher.map((data, index) => {
                    const expirationLeft = getTimeLeft(data.expiration);
                    const amountOff = getAmountOff(data);
                    return (
                      <Vouchers
                        key={index}
                        expirationLeft={expirationLeft}
                        description={data.description}
                        voucherData={data}
                        amountOff={amountOff}
                        from="home"
                        logo={ICON.WHITE_PARCELS_LOGO}
                        voucherIcon={ICON.VOUCHER_CARD_ICON_WHITE}
                        prevPath={prevPath}
                      />
                    );
                  })}
            </div>
          </div>
        )}
      </div>

      <div className="footer-wrapper">
        {hasDropOffAddress && (
          <FooterBtnHome
            className={`home-booking ${!isFilledUpFields ? 'disabled' : ''}`}
            childClass="radius"
            label="Review Order"
            onClick={() => {
              if (!isFilledUpFields) return;
              navigate(MODULE_PAYMENT_SUMMARY);
            }}
            hasFilled={hasFilled}
          />
        )}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Home;
