import NINJAVAN from '../../../assets/svg/home-booking/ninja_van_logo.svg';
import XDE from '../../../assets/svg/home-booking/xde_logo.svg';
import QUADX from '../../../assets/svg/home-booking/quadx_logo.svg';
import PANDAGO from '../../../assets/svg/home-booking/pandaGo_logo.svg';
import LALAMOVE from '../../../assets/svg/home-booking/lalamove_logo.svg';
import GRAB from '../../../assets/svg/home-booking/grab_logo.svg';
import JNT from '../../../assets/svg/home-booking/J&T_Express_logo.svg';
import ALLCOURIER from '../../../assets/svg/v4/v4_courier_list_all.svg';
import PESO_SIGN from '../../../assets/svg/v4/v4_peso_sign.svg';
import ERROR from '../../../assets/svg/v4/v4_error_courier.svg';

export const COURIER_LOGO = {
  NINJAVAN,
  XDE,
  QUADX,
  PANDAGO,
  LALAMOVE,
  GRAB,
  JNT,
  ALLCOURIER,
};

export const ICON = {
  PESO_SIGN,
  ERROR,
};
