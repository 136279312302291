import { useEffect, useState } from 'react';
import DPHDAO from '../utils/dao/DPH';
import { scheduledPayload } from '../utils/helpers/Payload';

const useStandardCouriers = (
  standardProps,
  userInfo,
  isStandard,
  hasAddress,
  isFromItemDetails
) => {
  const [standardCouriers, setStandardCouriers] = useState([]);
  const [isLoadingStandardCouriersQoute, setIsLoadingStandardCouriersQoute] = useState(true);
  const [errorStandardCouriersQoute, setErrorStandardCouriersQoute] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      try {
        setIsLoadingStandardCouriersQoute(true);
        setErrorStandardCouriersQoute(null);

        if (!isSubscribed) return;

        const sheduledQoutePayload = scheduledPayload(standardProps, userInfo);
        const standard = await DPHDAO.getAvailableScheduledPartner(sheduledQoutePayload);

        if (!isSubscribed) return;

        setStandardCouriers(standard.filterCouriers);
      } catch (error) {
        if (!isSubscribed) return;

        setErrorStandardCouriersQoute(
          error instanceof Error ? error : new Error('An unknown error occured')
        );
        console.error('Error fetching standard qouation:', error);
      } finally {
        if (isSubscribed) {
          setIsLoadingStandardCouriersQoute(false);
        }
      }
    };

    if (isStandard && hasAddress && isFromItemDetails) {
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [hasAddress, standardProps, userInfo]);

  return {
    standardCouriers,
    isLoadingStandardCouriersQoute,
    errorStandardCouriersQoute,
  };
};

export default useStandardCouriers;
