import NEW_ICON from '../../assets/images/AddressBook/bookmark_add.svg';
import BOOKMARK from '../../assets/images/AddressBook/bookmark.svg';
import CONTACT from '../../assets/images/AddressBook/contact.svg';
import CENTER_MAP from '../../assets/images/AddressBook/mapcenter.svg';
import PIN from '../../assets/images/AddressBook/pin.svg';
import TRASH from '../../assets/images/AddressBook/trash.svg';

import LOCATION from '../../assets/svg/location_on.svg';
import PHONE_NO from '../../assets/svg/home-booking/icon_phonebook.svg';
import SEARCH_PIN from '../../assets/images/AddressBook/searchpin.svg';
import EMPTY_ADDRESS from '../../assets/images/AddressBook/empty-address.png';
import MAP from '../../assets/images/AddressBook/map.svg';
import CANCEL_CLEAR from '../../assets/images/AddressBook/cancelclear.png';
import EMPTY_ADDRESS_BOOK from '../../assets/images/AddressBook/empty_addressbook.svg';
import MARKER from '../../assets/images/AddressBook/marker.svg';
import CURRENT_LOC from '../../assets/images/AddressBook/current_location.svg';
import KM_ZERO from '../../assets/images/AddressBook/km_zero.png';
import ADDRESSBOOK_ICON from '../../assets/images/AddressBook/address-book.svg';
import SEARCH from '../../assets/svg/icon_search_white.svg';
import ARROW_RIGHT from '../../assets/svg/icon_arrow_right.svg';

import L300 from '../../assets/images/Vehicle/L300.svg';
import PICKUP_TRUCK from '../../assets/images/Vehicle/L300.svg';
import MOTORCYCLE from '../../assets/images/Vehicle/motorcycle.svg';
import MPV from '../../assets/images/Vehicle/mpv.svg';
import SEDAN from '../../assets/images/Vehicle/sedan.svg';
import SUV from '../../assets/images/Vehicle/sedan.svg';
import TRUCK from '../../assets/images/Vehicle/truck.svg';

import ADD_ADDRESS_BTN from '../../assets/images/OndemandDelivery/add_address_button.png';
import EDIT from '../../assets/images/OndemandDelivery/edit.png';
import BOOKMARK_ADD from '../../assets/images/OndemandDelivery/bookmark_add.png';
import ON_DEMAND from '../../assets/images/OndemandDelivery/icon_menu_on-demand.png';
import GCASH from '../../assets/images/Icons/gcash.png';

import DOCUMENTS from '../../assets/images/OndemandDelivery/documents.svg';
import CLOTHING from '../../assets/images/OndemandDelivery/clothing.svg';
import FOOD from '../../assets/images/OndemandDelivery/food.svg';
import FRAGILE from '../../assets/images/OndemandDelivery/fragile.svg';
import ELECTRONICS from '../../assets/images/OndemandDelivery/electronics.svg';
import MEDICAL from '../../assets/images/OndemandDelivery/medical.svg';
import OTHERS from '../../assets/images/OndemandDelivery/others.svg';
import NO_ORDER_HOME from '../../assets/images/OndemandDelivery/no_orders_yet_animated.svg';
import NO_ORDER_ARROW_HOME from '../../assets/images/OndemandDelivery/arrow_new_order.svg';
import NO_ORDER_ALL from '../../assets/images/OndemandDelivery/no_orders_all.png';

import LIGHTNING from '../../assets/images/OndemandDelivery/lightning.svg';
import SELECTED from '../../assets/images/OndemandDelivery/selected.svg';
import CLOCK from '../../assets/images/OndemandDelivery/clock.svg';
import V4_CLOCK from '../../assets/images/OndemandDelivery/v4_clock.svg';

import BOOK_ANY from '../../assets/images/OndemandDelivery/book_any_icon1.svg';
import HELP from '../../assets/images/OndemandDelivery/help.svg';
import HELP_GRAY from '../../assets/images/OndemandDelivery/help_gray.svg';

import FOOTER from '../../assets/images/Banners/footer.png';
import ORDER_DETAILS from '../../assets/images/Banners/overlay.png';
import COMING_SOON from '../../assets/images/Maintenance/comingsoonios.jpg';

import STANDARD from '../../assets/images/StandardDelivery/icon_menu_standard.png';
import PICK_UP from '../../assets/images/StandardDelivery/form_pickup_icon.png';
import DROP_OFF from '../../assets/images/StandardDelivery/form_dropoff_icon.png';
import SMALL from '../../assets/images/StandardDelivery/Box - Small-v2.svg';
import MEDIUM from '../../assets/images/StandardDelivery/Box - Medium-v2.svg';
import LARGE from '../../assets/images/StandardDelivery/Box - Large-v2.svg';
import EXTRA_LARGE from '../../assets/images/StandardDelivery/Box - Extra-Large-v2.svg';
import ICON_BASE_INFO from '../../assets/svg/icon_base_info.svg';

import VOUCHER_ICON from '../../assets/svg/icon_base_voucher.svg';
import CASHBACK_ICON from '../../assets/svg/icon_cashback.svg';
import CASHBACK_ICON_GRAY from '../../assets/svg/icon_base_gray_cashback.svg';
import PAYMENT_ICON_GRAY from '../../assets/svg/icon_base_gray_payment.svg';
import DECLARED_PRICE_ICON from '../../assets/svg/icon_base_declare_value.svg';
import GINSURE from '../../assets/svg/ginsure_logo.svg';

import FTR_ALL_ORDERS from '../../assets/svg/Footer/footer_allOrders.svg';
import FTR_CASHBACK from '../../assets/svg/Footer/footer_cashback.svg';
import FTR_HELP from '../../assets/svg/Footer/footer_help.svg';
import FTR_ACCOUNT from '../../assets/svg/Footer/footer_account.svg';

import V4_ACCOUNT from '../../v4/assets/svg/footer/accont.svg';
import V4_CASHBACK from '../../v4/assets/svg/footer/cashback.svg';
import V4_HELP from '../../v4/assets/svg/footer/helpCenter.svg';
import V4_HOME from '../../v4/assets/svg/footer/home.svg';
import V4_ALL_ORDERS from '../../v4/assets/svg/footer/orders.svg';

import TWO_WHEEL from '../../assets/images/Couriers Images/icon_base_mini_motorcycle.svg';
import FOUR_WHEEL from '../../assets/images/Couriers Images/icon_base_mini_vehicle.svg';
import PARCEL from '../../assets/images/Couriers Images/icon_base_mini_parcel.svg';

import ARRW_RGT from '../../assets/svg/v3/arrow-right.svg';

import WHITE_PARCELS_LOGO from '../../assets/svg/white-logo.svg';
import PINK_PARCELS_LOGO from '../../assets/svg/pink-logo.svg';
import PINK_CLOCK from '../../assets/svg/pink_icon_clock.svg';
import VOUCHER_CARD_ICON_WHITE from '../../assets/svg/voucher_card_icon.svg';
import VOUCHER_CARD_ICON_GREEN from '../../assets/svg/voucher_card_icon_green.svg';
import SELECT_VOUCHER from '../../assets/svg/select-voucher.svg';
import UNCHECK_CIRCLE from '../../assets/svg/un-check.svg';
import CHECKED_CIRCLE from '../../assets/svg/check-circle.svg';

import V4_SEARCH from '../../assets/svg/v4/v4_header_search.svg';
import V4_HEADER_LOGO from '../../assets/svg/v4/v4_header_logo.svg';

export const BG = {
  FOOTER,
  KM_ZERO,
  ORDER_DETAILS,
  COMING_SOON,
};

export const SCHEDULE = {
  LIGHTNING,
  SELECTED,
  CLOCK,
  V4_CLOCK,
};

export const ITEM_TYPES = {
  FOOD,
  MEDICAL,
  FRAGILE,
  CLOTHING,
  DOCUMENTS,
  ELECTRONICS,
  OTHERS,
};

export const ICON = {
  MAP,
  PIN,
  EDIT,
  GCASH,
  TRASH,
  CONTACT,
  NEW_ICON,
  BOOKMARK,
  PHONE_NO,
  ON_DEMAND,
  STANDARD,
  PICK_UP,
  DROP_OFF,
  CENTER_MAP,
  CANCEL_CLEAR,
  BOOKMARK_ADD,
  EMPTY_ADDRESS,
  ADD_ADDRESS_BTN,
  SEARCH_PIN,
  EMPTY_ADDRESS_BOOK,
  MARKER,
  CURRENT_LOC,
  ADDRESSBOOK_ICON,
  NO_ORDER_HOME,
  NO_ORDER_ARROW_HOME,
  NO_ORDER_ALL,
  BOOK_ANY,
  HELP,
  HELP_GRAY,
  VOUCHER_ICON,
  CASHBACK_ICON,
  CASHBACK_ICON_GRAY,
  PAYMENT_ICON_GRAY,
  DECLARED_PRICE_ICON,
  ICON_BASE_INFO,
  SEARCH,
  ARROW_RIGHT,
  LOCATION,
  WHITE_PARCELS_LOGO,
  PINK_PARCELS_LOGO,
  PINK_CLOCK,
  VOUCHER_CARD_ICON_WHITE,
  VOUCHER_CARD_ICON_GREEN,
  SELECT_VOUCHER,
  UNCHECK_CIRCLE,
  CHECKED_CIRCLE,
  V4_SEARCH,
  V4_HEADER_LOGO,
};

export const ON_DEMAND_ICON = {
  SUV,
  MPV,
  L300,
  SEDAN,
  TRUCK,
  PICKUP_TRUCK,
  MOTORCYCLE,
};

export const STANDARD_ICON = {
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
};

export const INSURANCE_ICONS = {
  GINSURE,
};

export const FOOTER_ICONS = {
  FTR_ALL_ORDERS,
  FTR_CASHBACK,
  FTR_HELP,
  FTR_ACCOUNT,
};

export const V3_ICONS = {
  ARRW_RGT,
};

export const WHEELS = {
  TWO_WHEEL,
  FOUR_WHEEL,
  PARCEL,
};

export const V4_FTR = {
  V4_HOME,
  V4_ALL_ORDERS,
  V4_CASHBACK,
  V4_HELP,
  V4_ACCOUNT,
};