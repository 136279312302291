import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';

import {
  setDropOff as setOndemandDropOff,
  setLocation as setOndemandLocation,
  setPickUp as setOndemandPickUp,
} from '../../../../shared/redux/onDemand/actions';
import {
  setDropOff as setStandardDropOff,
  setLocation as setStandardLocation,
  setPickUp as setStandardPickUp,
} from '../../../../shared/redux/standard/actions';

import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AddressPin from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import IconSearch from '../../../../shared/assets/svg/home-booking/icon_search.svg';

const SearchLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: 'ph' },
  };

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);

  const { formTab } = useSelector(state => state.homeReducer);
  const deliveryType = 'onDemand';
  const isOndemand = formTab === deliveryType;

  const deliveryProps = isOndemand ? onDemandProps : standardProps;

  const { pickup = {}, dropOff = {}, pointLocation, location } = deliveryProps;

  const { senderDetails = {} } = pickup;
  const { recipientDetails = {} } = dropOff;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      autoCompleteRef.current.addListener('place_changed', async () => {
        const place = await autoCompleteRef.current.getPlace();
        const addressComponents = place?.address_components;

        let city = '';
        let province = '';
        if (!addressComponents) return;
        addressComponents.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            province = component.long_name;
          }
        });

        const address = `${place.name} ${city}. ${province}`;
        isOndemand
          ? dispatch(setOndemandLocation(`${place.name} ${place.vicinity}`))
          : dispatch(setStandardLocation(`${place.name} ${place.vicinity}`));
        if (pointLocation === 'PickUp') {
          let setPickUpAdd = {
            address,
            ...senderDetails,
            city,
            province,
          };
          isOndemand
            ? dispatch(setOndemandPickUp(setPickUpAdd))
            : dispatch(setStandardPickUp(setPickUpAdd));
        }
        if (pointLocation === 'DropOff') {
          let setDropOffAdd = { address, ...recipientDetails, city, province };
          isOndemand
            ? dispatch(setOndemandDropOff(setDropOffAdd))
            : dispatch(setStandardDropOff(setDropOffAdd));
        }
        navigate(
          isOndemand
            ? MODULE_PATH.ADDRESS.ON_DEMAND.PICKUP_DETAILS
            : MODULE_PATH.ADDRESS.STANDARD.PICKUP_DETAILS,
          { state: { showAutoComplete: false } }
        );
      });
    }

    return () => {};
  }, [onDemandProps, standardProps, location]);

  return (
    <div className="container__search__address">
      <div className="container__header">
        <Button
          className="back-button"
          icon={<ArrowLeftOutlined />}
          onClick={() =>
            navigate(
              isOndemand
                ? MODULE_PATH.ADDRESS.ON_DEMAND.SELECT_ADDRESS
                : MODULE_PATH.ADDRESS.STANDARD.SELECT_ADDRESS
            )
          }
        />
        <span className='lbl-base'>Destination Details</span>
      </div>
      <div className="search-location">
        <img src={IconSearch} alt="search" />
        <input placeholder="Search Address" className="search-address-input" ref={inputRef} />
      </div>
    </div>
  );
};
export default SearchLocation;
