import React from 'react';

const StandardDeliveryDays = () => {
  return (
    <div className="standard__delivery__days">
      <span>Pickup</span> will take place within <span>1-3 business days </span>
      excluding holidays, and <span>delivery</span> will follow within
      <span> 2-3 business days.</span>
      <div className="note">
        <span style={{ color: '#E64E75' }}>Note:</span> Make sure to attach the order waybill before
        pickup. Orders with no waybill will not be picked up by the rider.
      </div>
    </div>
  );
};

export default StandardDeliveryDays;
