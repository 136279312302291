import React from 'react';
import CourierList from '../CourierList';

const SimilarCouriers = ({ orderType, title, isReselect }) => {
  return (
    <div className="couriers-drawer-container">
      <span className="title hdln-block">{title}</span>
      <CourierList orderType={orderType} isDrawer={true} isReselect={isReselect} />
    </div>
  );
};

export default SimilarCouriers;
