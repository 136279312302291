import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { MODULE_PATH } from '../../../../../shared/constants/Module';
import { FOOTER_ICON, FOOTER_COLOR } from '../../../../../shared/v4/assets/svg/footer/FooterIcon';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state.usersReducer.currentUser);

  const handleNavigateAllOrder = () => {
    navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS, { state: { path: MODULE_PATH.HOME } });
  };

  const handleNavigateHome = () => {
    navigate(MODULE_PATH.HOME, { state: { path: MODULE_PATH.HOME } });
  };

  const gotoCashBack = () => navigate(MODULE_PATH.MY_ACCOUNTS.CASH_BACK);
  const goToHelp = () => navigate(MODULE_PATH.EXTRAS.HELP_CENTER);
  const goToMyAccounts = () => navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);

  const pathName = location.pathname;
  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="menu-item" onClick={() => handleNavigateHome()}>
          <FOOTER_ICON
            color={`${pathName === MODULE_PATH.HOME ? FOOTER_COLOR.ACTIVE : FOOTER_COLOR.DEFAULT}`}
            type="HomeIcon"
            label="Home"
          />
        </div>
        <div className="menu-item" onClick={() => handleNavigateAllOrder()}>
          <FOOTER_ICON
            color={`${pathName === MODULE_PATH.ORDERS.VIEW_ALL_ORDERS ? FOOTER_COLOR.ACTIVE : FOOTER_COLOR.DEFAULT}`}
            type="OrdersIcon"
            label="All Orders"
          />
        </div>
        <div className="menu-item" onClick={() => gotoCashBack()}>
          <FOOTER_ICON
            color={`${pathName === MODULE_PATH.MY_ACCOUNTS.CASH_BACK ? FOOTER_COLOR.ACTIVE : FOOTER_COLOR.DEFAULT}`}
            type="CashbackIcon"
            label="My Cashback"
          />
        </div>
        <div className="menu-item" onClick={() => goToHelp()}>
          <FOOTER_ICON
            color={`${pathName === MODULE_PATH.EXTRAS.HELP_CENTER ? FOOTER_COLOR.ACTIVE : FOOTER_COLOR.DEFAULT}`}
            type="HelpIcon"
            label="Help Center"
          />
        </div>
        <div className="menu-item" onClick={() => goToMyAccounts()}>
          <FOOTER_ICON
            color={`${pathName === MODULE_PATH.MY_ACCOUNTS.MAIN ? FOOTER_COLOR.ACTIVE : FOOTER_COLOR.DEFAULT}`}
            type="AccountIcon"
            label="Account"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
