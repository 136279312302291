import React, { useEffect, useState } from 'react';
import StickyHeader from '../StickyHeader';
import { MODULE_PATH } from '../../constants/Module';
import GcashLogo from '../../assets/svg/home-booking/gcash_logo.svg';
import IconInsurnce from '../../assets/svg/home-booking/icon_insurance.svg';
import IconMotorcycleGray from '../../assets/svg/home-booking/icon_motorcycle_gray.svg';
import IconPackageGray from '../../assets/svg/home-booking/icon_package_gray.svg';
import IconPeso from '../../assets/svg/home-booking/icon_peso.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FooterBtn from '../FooterBtn';
import { useDispatch, useSelector } from 'react-redux';
import { BOOK_ANY, COURIER_NAME, OFFER_TYPES, ParcelsPH } from '../../utils/enums/AppConstants';
import BOOKANY_IMAGE from '../../assets/svg/icon_base_book-any-courier.svg';
import { COURIER_IMAGES } from '../../utils/enums/CourierImages';
import { STANDARD_COURIERS } from '../../utils/enums/StandardDelivery';
import {
  capitalizeName,
  formatBills,
  getAmountOff,
  isEmpty,
} from '../../utils/helpers/purefunctions';
import { setDriversTip, setVoucherAmount } from '../../redux/onDemand/actions';
import Voucher from '../../../app/body/Payment/Voucher';
import PaymentDetails from '../PaymentDetails';
import { PERCENT_BASE_FEE } from '../../utils/enums/DeliveryConstants';
import DriverTipList from '../DriversTipList';
import { Flex, Select, Spin, message } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { setLoader } from '../../redux/home/actions';
import { setItemPrice as standardItemPrice } from '../../redux/standard/actions';
import { setItemPrice as onDemandItemPrice } from '../../redux/onDemand/actions';
import { ICON } from '../../utils/enums/AppIcons';
import PaymentDAO from '../../utils/dao/PaymentDAO';
import LoaderSpinner from '../LoaderSpinner';
import VoucherCodeBG from '../../assets/svg/voucher-vg-green.svg';
import {
  fetchReferralCode,
  fetchReferralOffer,
  fetchReferralRedemptions,
  setFetched,
} from '../../redux/referral/action';

const PaymentSummary = ({
  useCashBack,
  cashBack,
  handleCashBackSwitch,
  shippingFee,
  convenienceFee,
  rate,
  payWithGCash,
  isPaymentCliked,
  setIsPaymentClicked,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tip, setTip] = useState(0);
  const [voucherValid, isVoucherValid] = useState(true);
  const [declaredValue, setDeclaredValue] = useState('');
  const [referralTag, setReferralTag] = useState('');
  const [showDriversTipDrawer, setShowDriversTipDrawer] = useState(false);

  const onDemandProps = useSelector(state => state.onDemandReducer);
  const standardProps = useSelector(state => state.standardReducer);
  const product = standardProps.product;
  const { formTab, targetOrder, selectedCourierInfo } = useSelector(state => state.homeReducer);

  const userInfo = useSelector(state => state.usersReducer.currentUser);
  const { fetched, referralCode, referralOffer } = useSelector(state => state.referralReducer);

  const isScheduledServiceType = STANDARD_COURIERS.includes(targetOrder.courierId);
  const isStandard = formTab === 'standard';
  const { courier: onDemandcourier, vehicleType } = onDemandProps;
  const isBookAny = onDemandcourier.courier === BOOK_ANY;

  const pathKey = isStandard ? 'STANDARD' : 'ON_DEMAND';
  const formPath = MODULE_PATH[pathKey].FORM;
  const prevPath = MODULE_PATH.PAYMENT[pathKey].PAYMENT_SUMMARY;

  const courierRate = isStandard ? standardProps.courier.rate : onDemandcourier.rate;
  const courierType = isStandard ? { ...standardProps.courier } : { ...onDemandcourier };
  const refNo = isStandard ? standardProps.orderId : onDemandProps.orderId;

  const voucherCode = onDemandProps.voucherCode;
  const voucherAmount = onDemandProps.voucherAmount;
  const voucherDetails = onDemandProps.selectedVoucherDetails;
  const selectedVoucherCode = `${!voucherCode ? 'Select voucher' : getAmountOff(voucherDetails)}`;
  const voucherClass = selectedVoucherCode !== 'Select voucher' ? 'voucher-code' : 'select-voucher';

  const serviceType =
    isScheduledServiceType || isStandard ? standardProps.product.value : vehicleType.vehicleType;

  const standardDeclaredValue = (PERCENT_BASE_FEE / 100) * declaredValue;

  const standardAdditionalFees = isStandard
    ? isNaN(standardDeclaredValue)
      ? 0
      : Number(standardDeclaredValue)
    : 0;

  const additonalFees = parseFloat(convenienceFee) + parseFloat(tip);

  const totalRate = parseFloat(rate) + parseFloat(additonalFees) + standardAdditionalFees;
  const totalShippingFee = shippingFee === 0 ? Number(courierRate) : shippingFee;

  const courierInsurance = isBookAny ? 'Assigned Courier' : selectedCourierInfo.label;
  const isForAddTip = !isBookAny && !isStandard && courierType?.courier === 'LALAMOVE';

  const onDeclaredValue = isStandard ? standardItemPrice : onDemandItemPrice;

  const handleChangeDeclaredValue = e => {
    const { value } = e.target;

    if (value === '0') return setDeclaredValue('');
    if (!isEmpty(value)) {
      if (isNaN(value)) {
        return message.error('Please enter only numbers.', 3.0);
      }
      const num = String(Math.floor(value));

      const hasDecimal = String(value).includes('.');
      const [_, decimal] = String(value)?.split('.');

      if (hasDecimal) {
        if (decimal.length > 2) return;
      }

      if (num.length > 7) {
        return message.error('Field value should only contain 7 whole numbers.', 3.0);
      }

      dispatch(onDeclaredValue(parseFloat(value)));
      return setDeclaredValue(parseFloat(value));
    }

    dispatch(onDeclaredValue(parseFloat(value)));
    return setDeclaredValue(parseFloat(value));
  };

  const onClickPayment = () => {
    if (!voucherValid) {
      return message.error('Invalid Voucher code', 3.0);
    }
    dispatch(setDriversTip(tip));
    dispatch(onDeclaredValue(declaredValue));
    setIsPaymentClicked(true);
    payWithGCash(parseFloat(totalRate) - parseFloat(rate));
  };

  useEffect(() => {
    if (isPaymentCliked) {
      message.info('Please wait while processing you payment.', 3.0);
    }
  }, [isPaymentCliked]);

  useEffect(() => {
    const declaredVal = isStandard ? standardProps.itemPrice : onDemandProps.itemPrice;
    if (!isEmpty(declaredVal) && typeof declaredVal === 'number') {
      setDeclaredValue(parseFloat(Number(declaredVal)));
      dispatch(onDeclaredValue(parseFloat(Number(declaredVal))));
    }
  }, []);

  useEffect(() => {
    if (!fetched) {
      const actionFunctions = [fetchReferralOffer, fetchReferralCode, fetchReferralRedemptions];

      const promises = actionFunctions.map(actionFunction => dispatch(actionFunction(userInfo.id)));

      Promise.all(promises).then(() => {
        dispatch(setFetched(true));
      });
    }
  }, [fetched, userInfo.id, dispatch]);

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const validateVoucherCode = async () => {
      const inquireVoucherPayload = {
        userId: userInfo?.id,
        voucherCode,
        recordCreated: userInfo?.recordCreated || false,
        shippingFee: shippingFee,
        isBroadCast: isBookAny,
        provider: isBookAny ? '' : COURIER_NAME[courierType.courier],
        refNo: refNo,
        productName: ParcelsPH,
      };

      if (voucherCode) {
        const paymentDao = new PaymentDAO();
        const voucherResponse = await paymentDao.inquireVoucher(inquireVoucherPayload);

        const { success, message, data = {} } = voucherResponse;

        if (success && data?.referralCode === referralCode) {
          setLoading(false);
          setReferralTag('Referral');
          setErrorMessage('Invalid referral code');
          return;
        }

        if (success && 'referralCode' in data && data?.referralCode !== referralCode) {
          setSuccessMessage(`Referral code applied successfully.`);
          setReferralTag('Referral');
          return;
        }

        if (success) {
          if (!data?.offerType || data?.offerType === OFFER_TYPES.DISCOUNT) {
            const discountAmount = data?.percentage
              ? shippingFee * parseFloat(data.percentage / 100)
              : data.amount;
            let newAmount = discountAmount;

            if (data?.cappedAmount) {
              if (data.cappedAmount > 0 && discountAmount > data.cappedAmount) {
                newAmount = data.cappedAmount;
              }
            }

            setTimeout(() => setIsValid(true), 200);
            dispatch(setVoucherAmount(newAmount));
            setSuccessMessage(
              `Voucher code applied. Save P${formatBills(newAmount)} successfully.`
            );
          } else {
            setSuccessMessage(`Cashback voucher code successfully applied.`);
          }
        } else {
          setIsValid(false);
          setErrorMessage(message);
          isVoucherValid(false);
        }
      }
    };
    validateVoucherCode();
  }, [voucherCode]);

  const bgVoucherCodeStyle = {
    backgroundImage: voucherCode ? `url(${VoucherCodeBG})` : 'none',
    ...(voucherCode && {
      width: '89px',
      height: '23px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: '#0CAF70',
      fontSize: '10px',
    }),
  };

  return (
    <div className="summary-container">
      <StickyHeader title="Payment Summary" onPrevNavigate={{ path: formPath }} />
      <div className="summary-content">
        <div className="courier__details">
          <div className="order-details">
            <div className="courier-price">
              <img
                src={isBookAny ? BOOKANY_IMAGE : selectedCourierInfo.logo}
                alt={`${selectedCourierInfo.label} logo`}
              />
              <div className="courier-service">
                <span className="lbl-large">
                  {isBookAny ? 'Book Any Courier' : selectedCourierInfo.label}
                </span>
                {isStandard ? (
                  <div className="description">
                    <img src={IconPackageGray} alt="package" />
                    <span>
                      {' '}
                      {`${capitalizeName(product.value?.toLowerCase())} • ${
                        product?.description?.weightText || ''
                      }`}{' '}
                    </span>
                  </div>
                ) : (
                  <div className="description">
                    <img src={IconMotorcycleGray} alt="motorcycle" />
                    <span>{capitalizeName(serviceType?.toLowerCase())}</span>
                    <img src={IconPackageGray} alt="package" />
                    <span>{vehicleType.description.weightText}</span>
                  </div>
                )}
              </div>
              <span className="price">
                <img src={IconPeso} alt="Peso" />
                <span className="hdln-block hdln-block">{formatBills(courierRate)}</span>
              </span>
            </div>
            <div className="declare-value">
              <FontAwesomeIcon icon={solid('peso-sign')} />
              <input
                type="number"
                placeholder="Declared Value"
                value={declaredValue}
                onChange={e => handleChangeDeclaredValue(e)}
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </div>

            <div className="insurance">
              <img src={IconInsurnce} alt="Insurance" />
              <span className="cntnt-caption">
                Assigned courier's insurance is automatically applied to your item.
              </span>
            </div>
          </div>
          <div className="insurance-option">
            <span className="lbl-base">Item Insurance Option</span>
            <Select
              placeholder="Add Insurance"
              className="select-option"
              disabled={true}
              defaultValue={`${courierInsurance} Insurance`}
            />
          </div>
        </div>
        {isForAddTip && (
          <div className="drivers-tip-container">
            <div className="title lbl-base">Driver's Tip Option</div>
            <div className="drivers-tip" onClick={() => setShowDriversTipDrawer(true)}>
              <span>{tip ? `P ${parseFloat(tip)?.toFixed(2)} ` : 'Add a tip'}</span>
              <FontAwesomeIcon icon={solid('angle-down')} />
            </div>
          </div>
        )}
        <DriverTipList
          show={showDriversTipDrawer}
          onSetDriversTipDrawer={() => setShowDriversTipDrawer(false)}
          tip={tip}
          setTip={setTip}
        />
        {!isStandard && (
          <>
            <div className="select-voucher flex-row justify-between">
              <div className="label flex-row items-center gap-8px">
                <img src={ICON.SELECT_VOUCHER} alt="Select Voucher" />
                <span>Voucher</span>
              </div>
              <div
                className="place-holder flex-row items-center gap-8px"
                onClick={() =>
                  navigate(MODULE_PATH.EXTRAS.SELECT_VOUCHER, { state: { link: prevPath } })
                }
              >
                {loading ? (
                  <LoaderSpinner size="24px" />
                ) : (
                  <span className={voucherClass} style={bgVoucherCodeStyle}>
                    {!referralTag ? selectedVoucherCode : referralTag}
                  </span>
                )}
                <FontAwesomeIcon icon={solid('angle-right')} />
              </div>
            </div>
            {errorMessage && (
              <div className="voucher-error">
                <FontAwesomeIcon icon={solid('exclamation-circle')} className="info-icon" />
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="voucher-success">
                <FontAwesomeIcon icon={solid('circle-info')} className="info-icon" />
                {successMessage}
              </div>
            )}
          </>
        )}
        {/* {!isStandard && (
          <Voucher
            setVoucherAmount={setVoucherAmount}
            shippingFee={totalShippingFee}
            voucherAmount={Number(voucherAmount)}
            isBookAny={isBookAny}
            provider={isBookAny ? '' : COURIER_NAME[courierType.courier]}
            isVoucherValid={valid => isVoucherValid(valid)}
            refNo={refNo}
          />
        )} */}

        <PaymentDetails
          totalAmount={totalRate}
          shippingFee={totalShippingFee}
          referralTag={referralTag}
          convenienceFee={Number(convenienceFee)}
          tip={tip}
          voucherAmount={Number(voucherAmount)}
          cashBack={cashBack}
          useCashBack={useCashBack}
          handleCashBackSwitch={handleCashBackSwitch}
        />
        <div className="privacy__policy cntnt-caption">
          <div className="policy-and-conditions">
            By pressing the <b>Pay with GCash</b> button below, I agree with the
            <span> Privacy Policy </span> and <span> Terms and Conditions </span> of Parcels PH.
          </div>
          <div className="gcash-wallet">
            <img src={GcashLogo} alt="GCash Logo" />
            <span>
              Your<b> GCash wallet </b> will be charge <b>upon booking</b>
            </span>
          </div>
        </div>
      </div>
      {isPaymentCliked && <Spin fullscreen size="large" dotSizeLG={40} />}
      <FooterBtn
        className={`summary-payment ${isPaymentCliked ? 'disabled' : ''}`}
        childClass="radius"
        label="Pay with GCash"
        onClick={onClickPayment}
      />
    </div>
  );
};

export default PaymentSummary;
