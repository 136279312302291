/* global my */
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { ICON, WHEELS } from '../../utils/enums/AppIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { COURIER_IMAGES } from '../../utils/enums/CourierImages';
import { COURIER_VEHICLES, FOOD_PANDA, PANDAGO } from '../../utils/enums/Providers';
import moment from 'moment-timezone';
import {
  capitalizeName,
  formatAmount,
  isEmpty,
  paymentStatus,
  showConfirmationModal,
  formatUnderscoredText,
} from '../../utils/helpers/purefunctions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setFormTab, setFormView, setTargetOrder, setPrevRoute } from '../../redux/home/actions';
import { MODULE_PATH } from '../../constants/Module';
import {
  DEFAULT_REASON,
  DELIVERY_TYPE_VIEW,
  ORDER_STATUSES,
  REFUND_STATUS,
  COURIER_TAG_NAME,
} from '../../utils/enums/AppConstants';
import { message, Tour } from 'antd';
import { STANDARD_COURIERS } from '../../utils/enums/StandardDelivery';
import PaymentDAO from '../../utils/dao/PaymentDAO';
import Tap from '../../assets/svg/home-booking/Tap.svg';
import { setCurrentUser } from '../../redux/users/actions';
import Login from '../../utils/dao/Login';
import arrowRight from '../../assets/images/OndemandDelivery/arrow_right.svg';

const OrdersCard = ({ orders, isOngoing }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refViewAll = useRef(null);

  const targetOrder = useSelector(state => state.homeReducer.targetOrder);
  const user = useSelector(state => state.usersReducer.currentUser);
  const appAccess = useSelector(state => state.appAccessReducer);
  const [showViewAllTour, onSetShowViewAllTour] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (refViewAll.current) {
      observer.observe(refViewAll.current);
    }

    return () => {
      if (refViewAll.current) {
        observer.unobserve(refViewAll.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      appAccess.viewAll && onSetShowViewAllTour(appAccess.viewAll);
    }
  }, [isVisible]);

  useEffect(() => {
    if (user?.recordCreated && !appAccess.viewAll) {
      const updateUserInfo = async () => {
        try {
          const currentUser = { ...user, tour: { ...(user?.tour || {}), touredViewAll: true } };
          dispatch(setCurrentUser(currentUser));

          const loginDao = new Login();
          await loginDao.updateUser(user.id, currentUser);

          onSetShowViewAllTour(false);
        } catch (error) {
          onSetShowViewAllTour(false);
          console.error('Error:', error);
        }
      };
      updateUserInfo();
    }
  }, [appAccess.viewAll]);

  const deliveryDetails = targetOrder.meta ? JSON.parse(targetOrder.meta) : {};

  const onClickSelectOrder = order => {
    dispatch(setTargetOrder(order));
    navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, {
      state: { link: MODULE_PATH.ORDERS.VIEW_ALL_ORDERS },
    });
  };

  const onClickForRefund = useCallback(
    async order => {
      const PaymentDao = new PaymentDAO();
      const { gcash, refNo } = order;
      const { paymentAmount, paymentRequestId, paymentId, cancelledReason } = gcash;
      const { Success } = REFUND_STATUS;
      let refund;
      let refundResultStatus;
      navigate(MODULE_PATH.EXTRAS.LOADER);
      refund = await PaymentDao.refund({
        postId: order.postId || null,
        mdbId: order?.mdbId,
        orderNo: refNo,
        paymentId,
        paymentRequestId,
        refundAmount: paymentAmount,
        contactNumber: user.gcashNumber,
        name: `${user.firstName} ${user.lastName}`,
        userId: user.id,
        refundReason: cancelledReason || DEFAULT_REASON,
      });
      refundResultStatus = refund.result?.resultStatus;
      if (refundResultStatus === Success) {
        return navigate(MODULE_PATH.ORDERS.ORDER_CANCELLED);
      }
      return navigate(MODULE_PATH.EXTRAS.ERROR, {
        state: { refundStatus: refundResultStatus, attempt: gcash?.refund?.attempt },
      });
    },
    [navigate, user.id]
  );

  const handleClickBookAgain = (order, isStandardDelivery) => {
    setFormView(MODULE_PATH.ON_DEMAND.MAIN);
    dispatch(
      setFormTab(isStandardDelivery ? DELIVERY_TYPE_VIEW.standard : DELIVERY_TYPE_VIEW.onDemand)
    );
    dispatch(setTargetOrder(order));
    dispatch(setPrevRoute('ViewAll'));
    navigate(MODULE_PATH.HOME);
    return;
  };

  const handleOpenLink = async order => {
    const deliveryDetails = order.meta ? JSON.parse(order.meta) : {};
    if (!deliveryDetails.trackingUrl) {
      message.error('Order has no tracking URL', 3.0);
      return;
    }
    showConfirmationModal(deliveryDetails.trackingUrl);
  };
  const stepsViewAll = [
    {
      title: 'Checking a new order?',
      description: 'New orders are easier to see now. Tap on them to know more! ',
      cover: (
        <div className="tour-delivery-type-description-bottom">
          <img src={Tap} alt="Tap to Continue" className="tap-to-continue-bottom" />
          <span>Top to Continue</span>
        </div>
      ),
      target: () => refViewAll.current,
    },
  ];

  const VehicleIcon = ({ value, isStandardDelivery }) => {
    if (isStandardDelivery) {
      return <img src={WHEELS.PARCEL} alt={value || ''} />;
    }

    if (value) {
      return (
        <img
          src={
            'bike' === value.toLowerCase() || 'motorcycle' === value.toLowerCase()
              ? WHEELS.TWO_WHEEL
              : WHEELS.FOUR_WHEEL
          }
          alt={value || ''}
        />
      );
    }
    return <img src={WHEELS.FOUR_WHEEL} alt={value || ''} />;
  };

  return (
    <div className="orders_wrapper">
      {orders.map((order, idx) => {
        const { refNo, metaFields, gcash, courierId, status, subStatus } = order;
        const deliveryDetails = order.meta ? JSON.parse(order.meta) : {};
        const isNotForPending = ['CANCELLED', 'DONE'].includes(order.status);
        const isStandardDelivery = STANDARD_COURIERS.includes(order.courierId);
        const productSize = !isEmpty(order?.product) ? capitalizeName(order?.product?.size) : '';
        const serviceType =
          capitalizeName(metaFields?.serviceType) ||
          (metaFields?.grabVehicleType && capitalizeName(metaFields?.grabVehicleType));
        const deliveryServiceType = isStandardDelivery ? productSize : serviceType;

        const isBookAny = order.isBroadcastOndemand;

        return (
          <div
            key={order.refNo}
            className="orders__card"
            ref={isOngoing && Number(idx) === 0 ? refViewAll : null}
          >
            <div className="row-1" onClick={() => onClickSelectOrder(order)}>
              <div className="column-1">
                <div className="label">
                  <span className='mlLbl-base'>{`Order No. ${refNo}`}</span>
                  <span className='cntnt-small-caption'>{moment(order.completionDateTime).format('ddd, MMM D, h:mm A')}</span>
                </div>
              </div>
              <div className="column-2" onClick={() => onClickSelectOrder(order)}>
                <span className="arrow-right">
                  <img src={arrowRight} alt="arrow-right" />
                </span>
              </div>
            </div>
            <div className="row-2" onClick={() => onClickSelectOrder(order)}>
              <div className="icons">
                <div className="circle-top"></div>
                <div className="line"></div>
                <div className="circle-bottom"></div>
              </div>
              <div className="location-details">
                <div className="from mlLbl-small">{order.customerName}</div>
                <div className="to mlLbl-small">{deliveryDetails.customerName}</div>
              </div>
            </div>
            <div className="row-3">
              <div className="courier-details">
                <img
                  src={isBookAny ? ICON.BOOK_ANY : COURIER_IMAGES[courierId]}
                  alt="courier logo"
                  className="courier-image"
                />
                <span className='cntnt-fine-caption'>{courierId in COURIER_TAG_NAME ? COURIER_TAG_NAME[courierId] : ''}</span>
              </div>
              <div className="courier-vehicle">
                <VehicleIcon value={deliveryServiceType} isStandardDelivery={isStandardDelivery} />
                <div className="service-type">{deliveryServiceType || 'Motorcycle'}</div>
              </div>
            </div>
          </div>
        );
      })}
      <div className='orders__card__spacer'>&nbsp;</div>
      {/* <Tour
        open={showViewAllTour}
        placement={'bottom'}
        steps={stepsViewAll}
        // onClose={() => onSetShowViewAllTour(false)}
        className="tour-delivery-type"
      /> */}
    </div>
  );
};

export default OrdersCard;
