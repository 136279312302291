import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import CourierList from './components/CourierList';
import Banner from './components/Banner';
import OngoingOrder from './components/OngoingOrder';
import VoucherLists from './components/VoucherLists';
import Footer from './components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { setPickupDateTime as setStandardPickupDateTime } from '../../../../shared/redux/standard/actions';
import CouriersDrawer from './components/modal/CouriersDrawer';
import { useVoucherCenter } from '../../../../shared/hooks/useVoucherCenter';
import { fetchPendingOrders } from '../../../../shared/redux/home/actions';
import { getServerTime, setTimeInterval } from '../../../../shared/utils/helpers/purefunctions';
import { setPickupDateTime } from '../../../../shared/redux/onDemand/actions';

const Home = () => {
  const dispatch = useDispatch();
  const [courierDrawer, setCourierDrawer] = useState(false);
  const [allDone, setAllDone] = useState(false);
  const [setServerTime, onSetServerTime] = useState(false);

  const userType = useSelector(state => state.usersReducer.userOrderTags.userType);
  const user = useSelector(state => state.usersReducer);
  const { currentUser, userOrderTags } = user;
  const onDemand = useSelector(state => state.onDemandReducer);
  const standard = useSelector(state => state.standardReducer);

  const { data, isLoading } = useVoucherCenter(userType);

  useEffect(() => {
    !setServerTime && setCurrentDateAndTime();
  }, [setServerTime]);

  const setCurrentDateAndTime = async () => {
    const currDateTime = await getServerTime();
    const dateTime = setTimeInterval(currDateTime);

    const intervalPres = 300000;

    const hasOnDemandPickupDateTime =
      onDemand.pickupDateTime && onDemand.pickupDateTime % intervalPres === 0;

    const hadStandardPickupDateTime =
      standard.pickupDateTime && standard.pickupDateTime % intervalPres === 0;
    // removed: pickupDateTime default value is null
    if (hasOnDemandPickupDateTime) {
      dispatch(setPickupDateTime(onDemand.pickupDateTime));
    } else {
      dispatch(setPickupDateTime(dateTime));
    }

    if (hadStandardPickupDateTime) {
      dispatch(setStandardPickupDateTime(standard.pickupDateTime));
    } else {
      dispatch(setStandardPickupDateTime(dateTime));
    }

    onSetServerTime(true);
  };

  useEffect(() => {
    const actionFunctions = [fetchPendingOrders];

    const promises = actionFunctions.map(actionFunction =>
      dispatch(actionFunction(currentUser.id, 'false'))
    );

    Promise.all(promises).then(() => {
      setAllDone(true);
    });
  }, [currentUser.id, dispatch]);

  return (
    <div className="home__container">
      <Header />
      <CourierList setCourierDrawer={() => setCourierDrawer(true)} isReselect={false} />
      <Banner />
      <OngoingOrder allDone={allDone} />
      <VoucherLists data={data} isLoading={isLoading} userType={userType} />
      <Footer />
      <CouriersDrawer onSetShow={() => setCourierDrawer(false)} show={courierDrawer} />
    </div>
  );
};

export default Home;
