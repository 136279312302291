import React, { memo } from 'react';
import arrowRight from '../../../../../shared/assets/images/OndemandDelivery/arrow_right.svg';
import kgImage from '../../../../../shared/assets/images/OndemandDelivery/kg.svg';
import approxWeight from '../../../../../shared/assets/images/OndemandDelivery/approxWeight.svg';
import { isEmpty } from '../../../../../shared/utils/helpers/purefunctions';
import { ENUM } from '../../../../../shared/utils/enums/v4/AppConstants';

const FieldSelection = ({ className, imageName, refIdx, ...rest }) => {
  const isFieldRequired = ['Item', 'Courier'].includes(imageName);
  const requiredCharIndicator = isFieldRequired ? '*' : '';

  const { product_size, courier_label } = ENUM.COURIERS_LIST;

  const CardDescription = ({ imageName, rest }) => {
    return (
      <span className="cntnt-small-caption">
        {imageName === 'Motorcycle' && !isEmpty(rest.selectedVehicle) && (
          <img src={kgImage} alt={imageName} />
        )}
        {imageName === 'Item' && rest.itemType && <img src={approxWeight} alt={imageName} />}
        {` ${rest.description} ${imageName === 'Item' && rest.itemType ? 'Kg(s)' : ''}`}
      </span>
    );
  };

  return (
    <div
      className={`field-container ${className} try`}
      // ref={refIdx === 'refProductSize' ? null : refs[refIdx]}
    >
      <div className={className} onClick={rest.onClick}>
        <img src={rest.imageSrc} alt={imageName} />
        <div className="title">
          {imageName === courier_label && !isEmpty(courier) ? (
            <span className="has-courier lbl-base">{rest.description}</span>
          ) : (
            <>
              {imageName === product_size && !isEmpty(rest.standardProps?.product) ? (
                <span className="lbl-base">
                  {product_size}
                  <span className="title-emphasize cntnt-small-caption">({rest.title})</span>
                  <span>{requiredCharIndicator}</span>
                </span>
              ) : (
                <span className="lbl-base">
                  {rest.title} <span>{requiredCharIndicator}</span>
                </span>
              )}
              <CardDescription imageName={imageName} rest={rest} />
            </>
          )}
        </div>
        {imageName === 'Courier' && <div className="price">{courierInfoRate()}</div>}
        <img src={arrowRight} alt="arrow-right" />
      </div>
    </div>
  );
};

export default memo(FieldSelection);
