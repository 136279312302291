import { useEffect, useState } from 'react';
import DPHDAO from '../utils/dao/DPH';
import { filterCourierPartner } from '../utils/helpers/purefunctions';
import { COURIERS } from '../utils/enums/DeliveryConstants';
import { onDemandPayload } from '../utils/helpers/Payload';

const useOnDemandCouriers = (
  onDemandProps,
  userInfo,
  isOndemand,
  hasAddress,
  isFromItemDetails
) => {
  const [onDemandCouriers, setOnDemandCouriers] = useState([]);
  const [isLoadingOnDemandCouriersQoute, setIsLoadingOnDemandCouriersQoute] = useState(true);
  const [errorOnDemandCouriersQoute, setErrorOnDemandCouriersQoute] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      try {
        setIsLoadingOnDemandCouriersQoute(true);
        setErrorOnDemandCouriersQoute(null);

        const couriersPartnersData = await DPHDAO.getPartners();
        if (!isSubscribed) return;

        const filteredPartners = filterCourierPartner(couriersPartnersData, COURIERS);
        const qoutationPayload = onDemandPayload(onDemandProps, filteredPartners, userInfo);

        const onDemandQouteAPI = await DPHDAO.getOnDemandQuotation(qoutationPayload);

        if (!isSubscribed) return;

        setOnDemandCouriers(onDemandQouteAPI);
      } catch (error) {
        if (!isSubscribed) return;

        setErrorOnDemandCouriersQoute(
          error instanceof Error ? error : new Error('An unknown error occured')
        );
        console.error('Error fetching onDemand qouation:', error);
      } finally {
        if (isSubscribed) {
          setIsLoadingOnDemandCouriersQoute(false);
        }
      }
    };

    if (isOndemand && hasAddress && isFromItemDetails) {
      fetchData();
    }

    return () => {
      isSubscribed = false;
    };
  }, [hasAddress, onDemandProps, userInfo]);

  return {
    onDemandCouriers,
    isLoadingOnDemandCouriersQoute,
    errorOnDemandCouriersQoute,
  };
};

export default useOnDemandCouriers;
