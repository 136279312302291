import { combineReducers, createStore, applyMiddleware, compose } from 'redux';

import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import homeReducer from './home/reducer';
import onDemandReducer from './onDemand/reducer';
import standardReducer from './standard/reducer';
import addressReducer from './addressBook/reducer';
import usersReducer from './users/reducers';
import transactionReducer from './transaction/reducers';
import bannerReducer from './banner/reducers';
import appAccessReducer from './app/reducers';
import referralReducer from './referral/reducer'

// const isBeta = process.env.ZONE === 'beta'
// const middleware = isBeta ? applyMiddleware(promise(), thunk, logger) : applyMiddleware(promise(), thunk)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
  homeReducer,
  onDemandReducer,
  standardReducer,
  addressReducer,
  usersReducer,
  transactionReducer,
  bannerReducer,
  appAccessReducer,
  referralReducer,
});

const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk, logger)));

export default store;
